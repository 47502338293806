"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Nowr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.51.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventApiAxiosParamCreator = exports.EmergencyApi = exports.EmergencyApiFactory = exports.EmergencyApiFp = exports.EmergencyApiAxiosParamCreator = exports.EmailApi = exports.EmailApiFactory = exports.EmailApiFp = exports.EmailApiAxiosParamCreator = exports.DiscountApi = exports.DiscountApiFactory = exports.DiscountApiFp = exports.DiscountApiAxiosParamCreator = exports.CartApi = exports.CartApiFactory = exports.CartApiFp = exports.CartApiAxiosParamCreator = exports.AdminProcessedPaymentsApi = exports.AdminProcessedPaymentsApiFactory = exports.AdminProcessedPaymentsApiFp = exports.AdminProcessedPaymentsApiAxiosParamCreator = exports.AdminInvoicesApi = exports.AdminInvoicesApiFactory = exports.AdminInvoicesApiFp = exports.AdminInvoicesApiAxiosParamCreator = exports.AdminApi = exports.AdminApiFactory = exports.AdminApiFp = exports.AdminApiAxiosParamCreator = exports.TicketInputValidationMethodEnum = exports.TicketInputTypeEnum = exports.TicketValidationMethodEnum = exports.TicketTypeEnum = exports.PublicTicketTypeEnum = exports.InvoiceTypeEnum = exports.InputEditTicketValidationMethodEnum = exports.InputEditTicketTypeEnum = exports.InputDownloadTicketReportsForEventDashboardFilterTicketEnum = exports.InputCreateOrUpdateMangoHostBusinessTypeEnum = exports.InputCreateOrUpdateMangoHostTypeEnum = exports.InputCreateNewCartPlatformEnum = exports.InputCreateInvoiceTypeEnum = exports.EventQuestionInputTypeEnum = exports.EventQuestionTypeEnum = exports.EventImageMediaTypeEnum = exports.EventStatusEnum = exports.DiscountInputTypeEnum = exports.DiscountTypeEnum = exports.CartPaymentMethodEnum = exports.CartStatusEnum = void 0;
exports.OutputModelsApiFactory = exports.OutputModelsApiFp = exports.OutputModelsApiAxiosParamCreator = exports.MangoUboDeclarationApi = exports.MangoUboDeclarationApiFactory = exports.MangoUboDeclarationApiFp = exports.MangoUboDeclarationApiAxiosParamCreator = exports.MangoPayApi = exports.MangoPayApiFactory = exports.MangoPayApiFp = exports.MangoPayApiAxiosParamCreator = exports.MangoKycDocumentApi = exports.MangoKycDocumentApiFactory = exports.MangoKycDocumentApiFp = exports.MangoKycDocumentApiAxiosParamCreator = exports.InvoiceApi = exports.InvoiceApiFactory = exports.InvoiceApiFp = exports.InvoiceApiAxiosParamCreator = exports.InputModelsApi = exports.InputModelsApiFactory = exports.InputModelsApiFp = exports.InputModelsApiAxiosParamCreator = exports.HostStatsApi = exports.HostStatsApiFactory = exports.HostStatsApiFp = exports.HostStatsApiAxiosParamCreator = exports.HostSoldApi = exports.HostSoldApiFactory = exports.HostSoldApiFp = exports.HostSoldApiAxiosParamCreator = exports.HealthCheckApi = exports.HealthCheckApiFactory = exports.HealthCheckApiFp = exports.HealthCheckApiAxiosParamCreator = exports.GoogleMapsApi = exports.GoogleMapsApiFactory = exports.GoogleMapsApiFp = exports.GoogleMapsApiAxiosParamCreator = exports.EventQuestionApi = exports.EventQuestionApiFactory = exports.EventQuestionApiFp = exports.EventQuestionApiAxiosParamCreator = exports.EventAnswerApi = exports.EventAnswerApiFactory = exports.EventAnswerApiFp = exports.EventAnswerApiAxiosParamCreator = exports.EventApi = exports.EventApiFactory = exports.EventApiFp = void 0;
exports.UserApi = exports.UserApiFactory = exports.UserApiFp = exports.UserApiAxiosParamCreator = exports.TicketSubGroupApi = exports.TicketSubGroupApiFactory = exports.TicketSubGroupApiFp = exports.TicketSubGroupApiAxiosParamCreator = exports.TicketGroupApi = exports.TicketGroupApiFactory = exports.TicketGroupApiFp = exports.TicketGroupApiAxiosParamCreator = exports.TicketEventDashboardApi = exports.TicketEventDashboardApiFactory = exports.TicketEventDashboardApiFp = exports.TicketEventDashboardApiAxiosParamCreator = exports.TicketEventApi = exports.TicketEventApiFactory = exports.TicketEventApiFp = exports.TicketEventApiAxiosParamCreator = exports.TicketApi = exports.TicketApiFactory = exports.TicketApiFp = exports.TicketApiAxiosParamCreator = exports.SeatConfiguratorApi = exports.SeatConfiguratorApiFactory = exports.SeatConfiguratorApiFp = exports.SeatConfiguratorApiAxiosParamCreator = exports.RefundsApi = exports.RefundsApiFactory = exports.RefundsApiFp = exports.RefundsApiAxiosParamCreator = exports.PurchaseApi = exports.PurchaseApiFactory = exports.PurchaseApiFp = exports.PurchaseApiAxiosParamCreator = exports.ProxyGmapsApi = exports.ProxyGmapsApiFactory = exports.ProxyGmapsApiFp = exports.ProxyGmapsApiAxiosParamCreator = exports.ProvincesApi = exports.ProvincesApiFactory = exports.ProvincesApiFp = exports.ProvincesApiAxiosParamCreator = exports.OutputModelsApi = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("./common");
// @ts-ignore
var base_1 = require("./base");
exports.CartStatusEnum = {
    Pending: 'pending',
    Completed: 'completed',
    Expired: 'expired',
    PaymentFailed: 'payment_failed',
    TickaError: 'ticka_error',
    TicketsSold: 'tickets_sold',
    PaymentInProgress: 'payment_in_progress',
    MoreThan10TicketsSold: 'more_than_10_tickets_sold',
    LimitSecondaryTicketingReached: 'limit_secondary_ticketing_reached',
    _3dsFailed: '3ds_failed'
};
exports.CartPaymentMethodEnum = {
    Card: 'card',
    Apple: 'apple',
    Google: 'google',
    Satispay: 'satispay'
};
exports.DiscountTypeEnum = {
    Fixed: 'fixed',
    Percentage: 'percentage'
};
exports.DiscountInputTypeEnum = {
    Fixed: 'fixed',
    Percentage: 'percentage'
};
exports.EventStatusEnum = {
    Valid: 'VALID',
    Draft: 'DRAFT'
};
exports.EventImageMediaTypeEnum = {
    Photo: 'PHOTO',
    Video: 'VIDEO'
};
exports.EventQuestionTypeEnum = {
    Open: 'open',
    Close: 'close'
};
exports.EventQuestionInputTypeEnum = {
    Open: 'open',
    Close: 'close'
};
exports.InputCreateInvoiceTypeEnum = {
    Invoice: 'invoice',
    C1: 'c1',
    C2: 'c2',
    C3: 'c3',
    Other: 'other'
};
exports.InputCreateNewCartPlatformEnum = {
    Web: 'WEB',
    App: 'APP'
};
exports.InputCreateOrUpdateMangoHostTypeEnum = {
    Natural: 'natural',
    Legal: 'legal'
};
exports.InputCreateOrUpdateMangoHostBusinessTypeEnum = {
    Soletrader: 'SOLETRADER',
    Organization: 'ORGANIZATION',
    Business: 'BUSINESS'
};
exports.InputDownloadTicketReportsForEventDashboardFilterTicketEnum = {
    All: 'all',
    Mine: 'mine'
};
exports.InputEditTicketTypeEnum = {
    Free: 'FREE',
    Paid: 'PAID'
};
exports.InputEditTicketValidationMethodEnum = {
    Manual: 'MANUAL',
    Qr: 'QR'
};
exports.InvoiceTypeEnum = {
    Invoice: 'invoice',
    C1: 'c1',
    C2: 'c2',
    C3: 'c3',
    Other: 'other'
};
exports.PublicTicketTypeEnum = {
    Free: 'FREE',
    Paid: 'PAID'
};
exports.TicketTypeEnum = {
    Free: 'FREE',
    Paid: 'PAID'
};
exports.TicketValidationMethodEnum = {
    Manual: 'MANUAL',
    Qr: 'QR'
};
exports.TicketInputTypeEnum = {
    Free: 'FREE',
    Paid: 'PAID'
};
exports.TicketInputValidationMethodEnum = {
    Manual: 'MANUAL',
    Qr: 'QR'
};
/**
 * AdminApi - axios parameter creator
 * @export
 */
var AdminApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Debug mango
         * @param {string} [mangoUserId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        debugMango: function (mangoUserId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/admin/mangoDebug/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            if (mangoUserId !== undefined) {
                                localVarQueryParameter['mangoUserId'] = mangoUserId;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Send email bug
         * @param {string} [key]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmailBug: function (key, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/admin/send-email-bug/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            if (key !== undefined) {
                                localVarQueryParameter['key'] = key;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Send purchase email
         * @param {string} purchaseOrderId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPurchaseEmail: function (purchaseOrderId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'purchaseOrderId' is not null or undefined
                            (0, common_1.assertParamExists)('sendPurchaseEmail', 'purchaseOrderId', purchaseOrderId);
                            localVarPath = "/admin/purchase-email/{purchaseOrderId}"
                                .replace("{".concat("purchaseOrderId", "}"), encodeURIComponent(String(purchaseOrderId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.AdminApiAxiosParamCreator = AdminApiAxiosParamCreator;
/**
 * AdminApi - functional programming interface
 * @export
 */
var AdminApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.AdminApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Debug mango
         * @param {string} [mangoUserId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        debugMango: function (mangoUserId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.debugMango(mangoUserId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Send email bug
         * @param {string} [key]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmailBug: function (key, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.sendEmailBug(key, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Send purchase email
         * @param {string} purchaseOrderId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPurchaseEmail: function (purchaseOrderId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.sendPurchaseEmail(purchaseOrderId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.AdminApiFp = AdminApiFp;
/**
 * AdminApi - factory interface
 * @export
 */
var AdminApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.AdminApiFp)(configuration);
    return {
        /**
         *
         * @summary Debug mango
         * @param {string} [mangoUserId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        debugMango: function (mangoUserId, options) {
            return localVarFp.debugMango(mangoUserId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Send email bug
         * @param {string} [key]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmailBug: function (key, options) {
            return localVarFp.sendEmailBug(key, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Send purchase email
         * @param {string} purchaseOrderId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPurchaseEmail: function (purchaseOrderId, options) {
            return localVarFp.sendPurchaseEmail(purchaseOrderId, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.AdminApiFactory = AdminApiFactory;
/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
var AdminApi = /** @class */ (function (_super) {
    __extends(AdminApi, _super);
    function AdminApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Debug mango
     * @param {string} [mangoUserId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    AdminApi.prototype.debugMango = function (mangoUserId, options) {
        var _this = this;
        return (0, exports.AdminApiFp)(this.configuration).debugMango(mangoUserId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Send email bug
     * @param {string} [key]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    AdminApi.prototype.sendEmailBug = function (key, options) {
        var _this = this;
        return (0, exports.AdminApiFp)(this.configuration).sendEmailBug(key, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Send purchase email
     * @param {string} purchaseOrderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    AdminApi.prototype.sendPurchaseEmail = function (purchaseOrderId, options) {
        var _this = this;
        return (0, exports.AdminApiFp)(this.configuration).sendPurchaseEmail(purchaseOrderId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return AdminApi;
}(base_1.BaseAPI));
exports.AdminApi = AdminApi;
/**
 * AdminInvoicesApi - axios parameter creator
 * @export
 */
var AdminInvoicesApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Create a new invoice and return it
         * @summary Create a new invoice
         * @param {InputCreateInvoice} [inputCreateInvoice]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvoice: function (inputCreateInvoice, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/admin/invoices/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputCreateInvoice, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary delete Invoice
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInvoice: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('deleteInvoice', 'id', id);
                            localVarPath = "/admin/invoices/{id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * List all invoices for with pagination
         * @summary List all invoices
         * @param {string} [ownerId]
         * @param {number} [page]
         * @param {number} [perPage]
         * @param {'asc' | 'desc'} [order]
         * @param {string} [orderBy]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllInvoices: function (ownerId, page, perPage, order, orderBy, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/admin/invoices/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            if (ownerId !== undefined) {
                                localVarQueryParameter['ownerId'] = ownerId;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (perPage !== undefined) {
                                localVarQueryParameter['perPage'] = perPage;
                            }
                            if (order !== undefined) {
                                localVarQueryParameter['order'] = order;
                            }
                            if (orderBy !== undefined) {
                                localVarQueryParameter['orderBy'] = orderBy;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.AdminInvoicesApiAxiosParamCreator = AdminInvoicesApiAxiosParamCreator;
/**
 * AdminInvoicesApi - functional programming interface
 * @export
 */
var AdminInvoicesApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.AdminInvoicesApiAxiosParamCreator)(configuration);
    return {
        /**
         * Create a new invoice and return it
         * @summary Create a new invoice
         * @param {InputCreateInvoice} [inputCreateInvoice]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvoice: function (inputCreateInvoice, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createInvoice(inputCreateInvoice, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary delete Invoice
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInvoice: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteInvoice(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * List all invoices for with pagination
         * @summary List all invoices
         * @param {string} [ownerId]
         * @param {number} [page]
         * @param {number} [perPage]
         * @param {'asc' | 'desc'} [order]
         * @param {string} [orderBy]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllInvoices: function (ownerId, page, perPage, order, orderBy, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listAllInvoices(ownerId, page, perPage, order, orderBy, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.AdminInvoicesApiFp = AdminInvoicesApiFp;
/**
 * AdminInvoicesApi - factory interface
 * @export
 */
var AdminInvoicesApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.AdminInvoicesApiFp)(configuration);
    return {
        /**
         * Create a new invoice and return it
         * @summary Create a new invoice
         * @param {InputCreateInvoice} [inputCreateInvoice]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvoice: function (inputCreateInvoice, options) {
            return localVarFp.createInvoice(inputCreateInvoice, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary delete Invoice
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInvoice: function (id, options) {
            return localVarFp.deleteInvoice(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * List all invoices for with pagination
         * @summary List all invoices
         * @param {string} [ownerId]
         * @param {number} [page]
         * @param {number} [perPage]
         * @param {'asc' | 'desc'} [order]
         * @param {string} [orderBy]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllInvoices: function (ownerId, page, perPage, order, orderBy, options) {
            return localVarFp.listAllInvoices(ownerId, page, perPage, order, orderBy, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.AdminInvoicesApiFactory = AdminInvoicesApiFactory;
/**
 * AdminInvoicesApi - object-oriented interface
 * @export
 * @class AdminInvoicesApi
 * @extends {BaseAPI}
 */
var AdminInvoicesApi = /** @class */ (function (_super) {
    __extends(AdminInvoicesApi, _super);
    function AdminInvoicesApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Create a new invoice and return it
     * @summary Create a new invoice
     * @param {InputCreateInvoice} [inputCreateInvoice]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminInvoicesApi
     */
    AdminInvoicesApi.prototype.createInvoice = function (inputCreateInvoice, options) {
        var _this = this;
        return (0, exports.AdminInvoicesApiFp)(this.configuration).createInvoice(inputCreateInvoice, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary delete Invoice
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminInvoicesApi
     */
    AdminInvoicesApi.prototype.deleteInvoice = function (id, options) {
        var _this = this;
        return (0, exports.AdminInvoicesApiFp)(this.configuration).deleteInvoice(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * List all invoices for with pagination
     * @summary List all invoices
     * @param {string} [ownerId]
     * @param {number} [page]
     * @param {number} [perPage]
     * @param {'asc' | 'desc'} [order]
     * @param {string} [orderBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminInvoicesApi
     */
    AdminInvoicesApi.prototype.listAllInvoices = function (ownerId, page, perPage, order, orderBy, options) {
        var _this = this;
        return (0, exports.AdminInvoicesApiFp)(this.configuration).listAllInvoices(ownerId, page, perPage, order, orderBy, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return AdminInvoicesApi;
}(base_1.BaseAPI));
exports.AdminInvoicesApi = AdminInvoicesApi;
/**
 * AdminProcessedPaymentsApi - axios parameter creator
 * @export
 */
var AdminProcessedPaymentsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Calculate payments for today
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronjobCalculatePayments: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/admin/processed-payments/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.AdminProcessedPaymentsApiAxiosParamCreator = AdminProcessedPaymentsApiAxiosParamCreator;
/**
 * AdminProcessedPaymentsApi - functional programming interface
 * @export
 */
var AdminProcessedPaymentsApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.AdminProcessedPaymentsApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Calculate payments for today
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronjobCalculatePayments: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.cronjobCalculatePayments(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.AdminProcessedPaymentsApiFp = AdminProcessedPaymentsApiFp;
/**
 * AdminProcessedPaymentsApi - factory interface
 * @export
 */
var AdminProcessedPaymentsApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.AdminProcessedPaymentsApiFp)(configuration);
    return {
        /**
         *
         * @summary Calculate payments for today
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronjobCalculatePayments: function (options) {
            return localVarFp.cronjobCalculatePayments(options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.AdminProcessedPaymentsApiFactory = AdminProcessedPaymentsApiFactory;
/**
 * AdminProcessedPaymentsApi - object-oriented interface
 * @export
 * @class AdminProcessedPaymentsApi
 * @extends {BaseAPI}
 */
var AdminProcessedPaymentsApi = /** @class */ (function (_super) {
    __extends(AdminProcessedPaymentsApi, _super);
    function AdminProcessedPaymentsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Calculate payments for today
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProcessedPaymentsApi
     */
    AdminProcessedPaymentsApi.prototype.cronjobCalculatePayments = function (options) {
        var _this = this;
        return (0, exports.AdminProcessedPaymentsApiFp)(this.configuration).cronjobCalculatePayments(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return AdminProcessedPaymentsApi;
}(base_1.BaseAPI));
exports.AdminProcessedPaymentsApi = AdminProcessedPaymentsApi;
/**
 * CartApi - axios parameter creator
 * @export
 */
var CartApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Create new cart
         * @param {InputCreateNewCart} [inputCreateNewCart]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewCart: function (inputCreateNewCart, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/cart/new";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputCreateNewCart, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Debug pdf
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        debugPdf: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('debugPdf', 'id', id);
                            localVarPath = "/cart/{id}/debugPdf"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Download pdf by cart id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPdfByCartId: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('downloadPdfByCartId', 'id', id);
                            localVarPath = "/cart/{id}/downloadPdf"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get cart by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCartById: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('getCartById', 'id', id);
                            localVarPath = "/cart/{id}/getSingle"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Send p d f cart
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPDFCart: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('sendPDFCart', 'id', id);
                            localVarPath = "/cart/{id}/sendPdf"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Start checkout
         * @param {string} id
         * @param {InputStartCheckout} [inputStartCheckout]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startCheckout: function (id, inputStartCheckout, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('startCheckout', 'id', id);
                            localVarPath = "/cart/{id}/startCheckout"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputStartCheckout, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Update info
         * @param {string} id
         * @param {InputUpdateInfoCart} [inputUpdateInfoCart]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInfo: function (id, inputUpdateInfoCart, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('updateInfo', 'id', id);
                            localVarPath = "/cart/{id}/updateInfo"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputUpdateInfoCart, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.CartApiAxiosParamCreator = CartApiAxiosParamCreator;
/**
 * CartApi - functional programming interface
 * @export
 */
var CartApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.CartApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Create new cart
         * @param {InputCreateNewCart} [inputCreateNewCart]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewCart: function (inputCreateNewCart, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createNewCart(inputCreateNewCart, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Debug pdf
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        debugPdf: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.debugPdf(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Download pdf by cart id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPdfByCartId: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.downloadPdfByCartId(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get cart by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCartById: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getCartById(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Send p d f cart
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPDFCart: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.sendPDFCart(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Start checkout
         * @param {string} id
         * @param {InputStartCheckout} [inputStartCheckout]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startCheckout: function (id, inputStartCheckout, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.startCheckout(id, inputStartCheckout, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Update info
         * @param {string} id
         * @param {InputUpdateInfoCart} [inputUpdateInfoCart]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInfo: function (id, inputUpdateInfoCart, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateInfo(id, inputUpdateInfoCart, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.CartApiFp = CartApiFp;
/**
 * CartApi - factory interface
 * @export
 */
var CartApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.CartApiFp)(configuration);
    return {
        /**
         *
         * @summary Create new cart
         * @param {InputCreateNewCart} [inputCreateNewCart]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewCart: function (inputCreateNewCart, options) {
            return localVarFp.createNewCart(inputCreateNewCart, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Debug pdf
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        debugPdf: function (id, options) {
            return localVarFp.debugPdf(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Download pdf by cart id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPdfByCartId: function (id, options) {
            return localVarFp.downloadPdfByCartId(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get cart by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCartById: function (id, options) {
            return localVarFp.getCartById(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Send p d f cart
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPDFCart: function (id, options) {
            return localVarFp.sendPDFCart(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Start checkout
         * @param {string} id
         * @param {InputStartCheckout} [inputStartCheckout]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startCheckout: function (id, inputStartCheckout, options) {
            return localVarFp.startCheckout(id, inputStartCheckout, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Update info
         * @param {string} id
         * @param {InputUpdateInfoCart} [inputUpdateInfoCart]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInfo: function (id, inputUpdateInfoCart, options) {
            return localVarFp.updateInfo(id, inputUpdateInfoCart, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.CartApiFactory = CartApiFactory;
/**
 * CartApi - object-oriented interface
 * @export
 * @class CartApi
 * @extends {BaseAPI}
 */
var CartApi = /** @class */ (function (_super) {
    __extends(CartApi, _super);
    function CartApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Create new cart
     * @param {InputCreateNewCart} [inputCreateNewCart]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    CartApi.prototype.createNewCart = function (inputCreateNewCart, options) {
        var _this = this;
        return (0, exports.CartApiFp)(this.configuration).createNewCart(inputCreateNewCart, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Debug pdf
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    CartApi.prototype.debugPdf = function (id, options) {
        var _this = this;
        return (0, exports.CartApiFp)(this.configuration).debugPdf(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Download pdf by cart id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    CartApi.prototype.downloadPdfByCartId = function (id, options) {
        var _this = this;
        return (0, exports.CartApiFp)(this.configuration).downloadPdfByCartId(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get cart by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    CartApi.prototype.getCartById = function (id, options) {
        var _this = this;
        return (0, exports.CartApiFp)(this.configuration).getCartById(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Send p d f cart
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    CartApi.prototype.sendPDFCart = function (id, options) {
        var _this = this;
        return (0, exports.CartApiFp)(this.configuration).sendPDFCart(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Start checkout
     * @param {string} id
     * @param {InputStartCheckout} [inputStartCheckout]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    CartApi.prototype.startCheckout = function (id, inputStartCheckout, options) {
        var _this = this;
        return (0, exports.CartApiFp)(this.configuration).startCheckout(id, inputStartCheckout, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Update info
     * @param {string} id
     * @param {InputUpdateInfoCart} [inputUpdateInfoCart]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    CartApi.prototype.updateInfo = function (id, inputUpdateInfoCart, options) {
        var _this = this;
        return (0, exports.CartApiFp)(this.configuration).updateInfo(id, inputUpdateInfoCart, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return CartApi;
}(base_1.BaseAPI));
exports.CartApi = CartApi;
/**
 * DiscountApi - axios parameter creator
 * @export
 */
var DiscountApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary deactivate a discount
         * @param {string} discountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateDiscountById: function (discountId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'discountId' is not null or undefined
                            (0, common_1.assertParamExists)('activateDiscountById', 'discountId', discountId);
                            localVarPath = "/discount/{discountId}/activate"
                                .replace("{".concat("discountId", "}"), encodeURIComponent(String(discountId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Can delete discount by id
         * @param {string} discountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canDeleteDiscountById: function (discountId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'discountId' is not null or undefined
                            (0, common_1.assertParamExists)('canDeleteDiscountById', 'discountId', discountId);
                            localVarPath = "/discount/{discountId}/can-delete"
                                .replace("{".concat("discountId", "}"), encodeURIComponent(String(discountId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary creates a new discount
         * @param {string} eventId
         * @param {DiscountInput} [discountInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDiscount: function (eventId, discountInput, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'eventId' is not null or undefined
                            (0, common_1.assertParamExists)('createDiscount', 'eventId', eventId);
                            localVarPath = "/discount/by-event/{eventId}"
                                .replace("{".concat("eventId", "}"), encodeURIComponent(String(eventId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(discountInput, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary deactivate a discount
         * @param {string} discountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateDiscountById: function (discountId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'discountId' is not null or undefined
                            (0, common_1.assertParamExists)('deactivateDiscountById', 'discountId', discountId);
                            localVarPath = "/discount/{discountId}/deactivate"
                                .replace("{".concat("discountId", "}"), encodeURIComponent(String(discountId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Delete discount by id
         * @param {string} discountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDiscountById: function (discountId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'discountId' is not null or undefined
                            (0, common_1.assertParamExists)('deleteDiscountById', 'discountId', discountId);
                            localVarPath = "/discount/{discountId}/"
                                .replace("{".concat("discountId", "}"), encodeURIComponent(String(discountId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary find a discount by it\'s code and event id
         * @param {string} code
         * @param {number} eventId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByDiscountCodeAndEventId: function (code, eventId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'code' is not null or undefined
                            (0, common_1.assertParamExists)('findByDiscountCodeAndEventId', 'code', code);
                            // verify required parameter 'eventId' is not null or undefined
                            (0, common_1.assertParamExists)('findByDiscountCodeAndEventId', 'eventId', eventId);
                            localVarPath = "/discount/by-code/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            if (code !== undefined) {
                                localVarQueryParameter['code'] = code;
                            }
                            if (eventId !== undefined) {
                                localVarQueryParameter['eventId'] = eventId;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Find discount by id
         * @param {string} discountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findDiscountById: function (discountId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'discountId' is not null or undefined
                            (0, common_1.assertParamExists)('findDiscountById', 'discountId', discountId);
                            localVarPath = "/discount/{discountId}/"
                                .replace("{".concat("discountId", "}"), encodeURIComponent(String(discountId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary List discounts by event id
         * @param {string} eventId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDiscountsByEventId: function (eventId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'eventId' is not null or undefined
                            (0, common_1.assertParamExists)('listDiscountsByEventId', 'eventId', eventId);
                            localVarPath = "/discount/by-event/{eventId}"
                                .replace("{".concat("eventId", "}"), encodeURIComponent(String(eventId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary List events with discounts
         * @param {number} [page]
         * @param {number} [size]
         * @param {boolean} [paginate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEventsWithDiscounts: function (page, size, paginate, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/discount/events";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (size !== undefined) {
                                localVarQueryParameter['size'] = size;
                            }
                            if (paginate !== undefined) {
                                localVarQueryParameter['paginate'] = paginate;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Parse csv for discount
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parseCsvForDiscount: function (file, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'file' is not null or undefined
                            (0, common_1.assertParamExists)('parseCsvForDiscount', 'file', file);
                            localVarPath = "/discount/utils/parse-csv";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            if (file !== undefined) {
                                localVarFormParams.append('file', file);
                            }
                            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = localVarFormParams;
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.DiscountApiAxiosParamCreator = DiscountApiAxiosParamCreator;
/**
 * DiscountApi - functional programming interface
 * @export
 */
var DiscountApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.DiscountApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary deactivate a discount
         * @param {string} discountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateDiscountById: function (discountId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.activateDiscountById(discountId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Can delete discount by id
         * @param {string} discountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canDeleteDiscountById: function (discountId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.canDeleteDiscountById(discountId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary creates a new discount
         * @param {string} eventId
         * @param {DiscountInput} [discountInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDiscount: function (eventId, discountInput, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createDiscount(eventId, discountInput, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary deactivate a discount
         * @param {string} discountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateDiscountById: function (discountId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deactivateDiscountById(discountId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Delete discount by id
         * @param {string} discountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDiscountById: function (discountId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteDiscountById(discountId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary find a discount by it\'s code and event id
         * @param {string} code
         * @param {number} eventId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByDiscountCodeAndEventId: function (code, eventId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findByDiscountCodeAndEventId(code, eventId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Find discount by id
         * @param {string} discountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findDiscountById: function (discountId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findDiscountById(discountId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary List discounts by event id
         * @param {string} eventId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDiscountsByEventId: function (eventId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listDiscountsByEventId(eventId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary List events with discounts
         * @param {number} [page]
         * @param {number} [size]
         * @param {boolean} [paginate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEventsWithDiscounts: function (page, size, paginate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listEventsWithDiscounts(page, size, paginate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Parse csv for discount
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parseCsvForDiscount: function (file, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.parseCsvForDiscount(file, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.DiscountApiFp = DiscountApiFp;
/**
 * DiscountApi - factory interface
 * @export
 */
var DiscountApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.DiscountApiFp)(configuration);
    return {
        /**
         *
         * @summary deactivate a discount
         * @param {string} discountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateDiscountById: function (discountId, options) {
            return localVarFp.activateDiscountById(discountId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Can delete discount by id
         * @param {string} discountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canDeleteDiscountById: function (discountId, options) {
            return localVarFp.canDeleteDiscountById(discountId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary creates a new discount
         * @param {string} eventId
         * @param {DiscountInput} [discountInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDiscount: function (eventId, discountInput, options) {
            return localVarFp.createDiscount(eventId, discountInput, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary deactivate a discount
         * @param {string} discountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateDiscountById: function (discountId, options) {
            return localVarFp.deactivateDiscountById(discountId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Delete discount by id
         * @param {string} discountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDiscountById: function (discountId, options) {
            return localVarFp.deleteDiscountById(discountId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary find a discount by it\'s code and event id
         * @param {string} code
         * @param {number} eventId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByDiscountCodeAndEventId: function (code, eventId, options) {
            return localVarFp.findByDiscountCodeAndEventId(code, eventId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Find discount by id
         * @param {string} discountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findDiscountById: function (discountId, options) {
            return localVarFp.findDiscountById(discountId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary List discounts by event id
         * @param {string} eventId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDiscountsByEventId: function (eventId, options) {
            return localVarFp.listDiscountsByEventId(eventId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary List events with discounts
         * @param {number} [page]
         * @param {number} [size]
         * @param {boolean} [paginate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEventsWithDiscounts: function (page, size, paginate, options) {
            return localVarFp.listEventsWithDiscounts(page, size, paginate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Parse csv for discount
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parseCsvForDiscount: function (file, options) {
            return localVarFp.parseCsvForDiscount(file, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.DiscountApiFactory = DiscountApiFactory;
/**
 * DiscountApi - object-oriented interface
 * @export
 * @class DiscountApi
 * @extends {BaseAPI}
 */
var DiscountApi = /** @class */ (function (_super) {
    __extends(DiscountApi, _super);
    function DiscountApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary deactivate a discount
     * @param {string} discountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountApi
     */
    DiscountApi.prototype.activateDiscountById = function (discountId, options) {
        var _this = this;
        return (0, exports.DiscountApiFp)(this.configuration).activateDiscountById(discountId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Can delete discount by id
     * @param {string} discountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountApi
     */
    DiscountApi.prototype.canDeleteDiscountById = function (discountId, options) {
        var _this = this;
        return (0, exports.DiscountApiFp)(this.configuration).canDeleteDiscountById(discountId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary creates a new discount
     * @param {string} eventId
     * @param {DiscountInput} [discountInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountApi
     */
    DiscountApi.prototype.createDiscount = function (eventId, discountInput, options) {
        var _this = this;
        return (0, exports.DiscountApiFp)(this.configuration).createDiscount(eventId, discountInput, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary deactivate a discount
     * @param {string} discountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountApi
     */
    DiscountApi.prototype.deactivateDiscountById = function (discountId, options) {
        var _this = this;
        return (0, exports.DiscountApiFp)(this.configuration).deactivateDiscountById(discountId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Delete discount by id
     * @param {string} discountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountApi
     */
    DiscountApi.prototype.deleteDiscountById = function (discountId, options) {
        var _this = this;
        return (0, exports.DiscountApiFp)(this.configuration).deleteDiscountById(discountId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary find a discount by it\'s code and event id
     * @param {string} code
     * @param {number} eventId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountApi
     */
    DiscountApi.prototype.findByDiscountCodeAndEventId = function (code, eventId, options) {
        var _this = this;
        return (0, exports.DiscountApiFp)(this.configuration).findByDiscountCodeAndEventId(code, eventId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Find discount by id
     * @param {string} discountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountApi
     */
    DiscountApi.prototype.findDiscountById = function (discountId, options) {
        var _this = this;
        return (0, exports.DiscountApiFp)(this.configuration).findDiscountById(discountId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary List discounts by event id
     * @param {string} eventId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountApi
     */
    DiscountApi.prototype.listDiscountsByEventId = function (eventId, options) {
        var _this = this;
        return (0, exports.DiscountApiFp)(this.configuration).listDiscountsByEventId(eventId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary List events with discounts
     * @param {number} [page]
     * @param {number} [size]
     * @param {boolean} [paginate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountApi
     */
    DiscountApi.prototype.listEventsWithDiscounts = function (page, size, paginate, options) {
        var _this = this;
        return (0, exports.DiscountApiFp)(this.configuration).listEventsWithDiscounts(page, size, paginate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Parse csv for discount
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountApi
     */
    DiscountApi.prototype.parseCsvForDiscount = function (file, options) {
        var _this = this;
        return (0, exports.DiscountApiFp)(this.configuration).parseCsvForDiscount(file, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return DiscountApi;
}(base_1.BaseAPI));
exports.DiscountApi = DiscountApi;
/**
 * EmailApi - axios parameter creator
 * @export
 */
var EmailApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Email test
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailTest: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/email-test/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.EmailApiAxiosParamCreator = EmailApiAxiosParamCreator;
/**
 * EmailApi - functional programming interface
 * @export
 */
var EmailApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.EmailApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Email test
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailTest: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.emailTest(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.EmailApiFp = EmailApiFp;
/**
 * EmailApi - factory interface
 * @export
 */
var EmailApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.EmailApiFp)(configuration);
    return {
        /**
         *
         * @summary Email test
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailTest: function (options) {
            return localVarFp.emailTest(options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.EmailApiFactory = EmailApiFactory;
/**
 * EmailApi - object-oriented interface
 * @export
 * @class EmailApi
 * @extends {BaseAPI}
 */
var EmailApi = /** @class */ (function (_super) {
    __extends(EmailApi, _super);
    function EmailApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Email test
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    EmailApi.prototype.emailTest = function (options) {
        var _this = this;
        return (0, exports.EmailApiFp)(this.configuration).emailTest(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return EmailApi;
}(base_1.BaseAPI));
exports.EmailApi = EmailApi;
/**
 * EmergencyApi - axios parameter creator
 * @export
 */
var EmergencyApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Send missing email from tickets
         * @param {string} [key]
         * @param {InputSendMissingEmailFromTickets} [inputSendMissingEmailFromTickets]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMissingEmailFromTickets: function (key, inputSendMissingEmailFromTickets, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/emergency/missingEmails";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            if (key !== undefined) {
                                localVarQueryParameter['key'] = key;
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputSendMissingEmailFromTickets, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.EmergencyApiAxiosParamCreator = EmergencyApiAxiosParamCreator;
/**
 * EmergencyApi - functional programming interface
 * @export
 */
var EmergencyApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.EmergencyApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Send missing email from tickets
         * @param {string} [key]
         * @param {InputSendMissingEmailFromTickets} [inputSendMissingEmailFromTickets]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMissingEmailFromTickets: function (key, inputSendMissingEmailFromTickets, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.sendMissingEmailFromTickets(key, inputSendMissingEmailFromTickets, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.EmergencyApiFp = EmergencyApiFp;
/**
 * EmergencyApi - factory interface
 * @export
 */
var EmergencyApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.EmergencyApiFp)(configuration);
    return {
        /**
         *
         * @summary Send missing email from tickets
         * @param {string} [key]
         * @param {InputSendMissingEmailFromTickets} [inputSendMissingEmailFromTickets]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMissingEmailFromTickets: function (key, inputSendMissingEmailFromTickets, options) {
            return localVarFp.sendMissingEmailFromTickets(key, inputSendMissingEmailFromTickets, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.EmergencyApiFactory = EmergencyApiFactory;
/**
 * EmergencyApi - object-oriented interface
 * @export
 * @class EmergencyApi
 * @extends {BaseAPI}
 */
var EmergencyApi = /** @class */ (function (_super) {
    __extends(EmergencyApi, _super);
    function EmergencyApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Send missing email from tickets
     * @param {string} [key]
     * @param {InputSendMissingEmailFromTickets} [inputSendMissingEmailFromTickets]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmergencyApi
     */
    EmergencyApi.prototype.sendMissingEmailFromTickets = function (key, inputSendMissingEmailFromTickets, options) {
        var _this = this;
        return (0, exports.EmergencyApiFp)(this.configuration).sendMissingEmailFromTickets(key, inputSendMissingEmailFromTickets, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return EmergencyApi;
}(base_1.BaseAPI));
exports.EmergencyApi = EmergencyApi;
/**
 * EventApi - axios parameter creator
 * @export
 */
var EventApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Checks if an event can be deleted
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canDeleteEventById: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('canDeleteEventById', 'id', id);
                            localVarPath = "/event/can-delete/{id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary get a list of my events with some additional info, like ticket sold and others
         * @param {'ALL' | 'VALID' | 'DRAFT'} [status]
         * @param {string} [qry]
         * @param {string} [page]
         * @param {string} [perPage]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMyEvents: function (status, qry, page, perPage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/event/mine";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            if (status !== undefined) {
                                localVarQueryParameter['status'] = status;
                            }
                            if (qry !== undefined) {
                                localVarQueryParameter['qry'] = qry;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (perPage !== undefined) {
                                localVarQueryParameter['perPage'] = perPage;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary get a list of my events with some additional info, with discounts and inEvidence
         * @param {string} [page]
         * @param {string} [perPage]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMyEventsWIthDiscountsAndEvidencePopulated: function (page, perPage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/event/withDiscountsAndEvidence";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (perPage !== undefined) {
                                localVarQueryParameter['perPage'] = perPage;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.EventApiAxiosParamCreator = EventApiAxiosParamCreator;
/**
 * EventApi - functional programming interface
 * @export
 */
var EventApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.EventApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Checks if an event can be deleted
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canDeleteEventById: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.canDeleteEventById(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary get a list of my events with some additional info, like ticket sold and others
         * @param {'ALL' | 'VALID' | 'DRAFT'} [status]
         * @param {string} [qry]
         * @param {string} [page]
         * @param {string} [perPage]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMyEvents: function (status, qry, page, perPage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listMyEvents(status, qry, page, perPage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary get a list of my events with some additional info, with discounts and inEvidence
         * @param {string} [page]
         * @param {string} [perPage]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMyEventsWIthDiscountsAndEvidencePopulated: function (page, perPage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listMyEventsWIthDiscountsAndEvidencePopulated(page, perPage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.EventApiFp = EventApiFp;
/**
 * EventApi - factory interface
 * @export
 */
var EventApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.EventApiFp)(configuration);
    return {
        /**
         *
         * @summary Checks if an event can be deleted
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canDeleteEventById: function (id, options) {
            return localVarFp.canDeleteEventById(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary get a list of my events with some additional info, like ticket sold and others
         * @param {'ALL' | 'VALID' | 'DRAFT'} [status]
         * @param {string} [qry]
         * @param {string} [page]
         * @param {string} [perPage]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMyEvents: function (status, qry, page, perPage, options) {
            return localVarFp.listMyEvents(status, qry, page, perPage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary get a list of my events with some additional info, with discounts and inEvidence
         * @param {string} [page]
         * @param {string} [perPage]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMyEventsWIthDiscountsAndEvidencePopulated: function (page, perPage, options) {
            return localVarFp.listMyEventsWIthDiscountsAndEvidencePopulated(page, perPage, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.EventApiFactory = EventApiFactory;
/**
 * EventApi - object-oriented interface
 * @export
 * @class EventApi
 * @extends {BaseAPI}
 */
var EventApi = /** @class */ (function (_super) {
    __extends(EventApi, _super);
    function EventApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Checks if an event can be deleted
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    EventApi.prototype.canDeleteEventById = function (id, options) {
        var _this = this;
        return (0, exports.EventApiFp)(this.configuration).canDeleteEventById(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary get a list of my events with some additional info, like ticket sold and others
     * @param {'ALL' | 'VALID' | 'DRAFT'} [status]
     * @param {string} [qry]
     * @param {string} [page]
     * @param {string} [perPage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    EventApi.prototype.listMyEvents = function (status, qry, page, perPage, options) {
        var _this = this;
        return (0, exports.EventApiFp)(this.configuration).listMyEvents(status, qry, page, perPage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary get a list of my events with some additional info, with discounts and inEvidence
     * @param {string} [page]
     * @param {string} [perPage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    EventApi.prototype.listMyEventsWIthDiscountsAndEvidencePopulated = function (page, perPage, options) {
        var _this = this;
        return (0, exports.EventApiFp)(this.configuration).listMyEventsWIthDiscountsAndEvidencePopulated(page, perPage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return EventApi;
}(base_1.BaseAPI));
exports.EventApi = EventApi;
/**
 * EventAnswerApi - axios parameter creator
 * @export
 */
var EventAnswerApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Create event answer
         * @param {EventAnswerInput} [eventAnswerInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEventAnswer: function (eventAnswerInput, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/event-answer/create";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(eventAnswerInput, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary List event answer
         * @param {string} eventId
         * @param {string} questionId
         * @param {InputListEventAnswer} [inputListEventAnswer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEventAnswer: function (eventId, questionId, inputListEventAnswer, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'eventId' is not null or undefined
                            (0, common_1.assertParamExists)('listEventAnswer', 'eventId', eventId);
                            // verify required parameter 'questionId' is not null or undefined
                            (0, common_1.assertParamExists)('listEventAnswer', 'questionId', questionId);
                            localVarPath = "/event-answer/event/{eventId}/question/{questionId}/list"
                                .replace("{".concat("eventId", "}"), encodeURIComponent(String(eventId)))
                                .replace("{".concat("questionId", "}"), encodeURIComponent(String(questionId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputListEventAnswer, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.EventAnswerApiAxiosParamCreator = EventAnswerApiAxiosParamCreator;
/**
 * EventAnswerApi - functional programming interface
 * @export
 */
var EventAnswerApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.EventAnswerApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Create event answer
         * @param {EventAnswerInput} [eventAnswerInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEventAnswer: function (eventAnswerInput, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createEventAnswer(eventAnswerInput, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary List event answer
         * @param {string} eventId
         * @param {string} questionId
         * @param {InputListEventAnswer} [inputListEventAnswer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEventAnswer: function (eventId, questionId, inputListEventAnswer, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listEventAnswer(eventId, questionId, inputListEventAnswer, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.EventAnswerApiFp = EventAnswerApiFp;
/**
 * EventAnswerApi - factory interface
 * @export
 */
var EventAnswerApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.EventAnswerApiFp)(configuration);
    return {
        /**
         *
         * @summary Create event answer
         * @param {EventAnswerInput} [eventAnswerInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEventAnswer: function (eventAnswerInput, options) {
            return localVarFp.createEventAnswer(eventAnswerInput, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary List event answer
         * @param {string} eventId
         * @param {string} questionId
         * @param {InputListEventAnswer} [inputListEventAnswer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEventAnswer: function (eventId, questionId, inputListEventAnswer, options) {
            return localVarFp.listEventAnswer(eventId, questionId, inputListEventAnswer, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.EventAnswerApiFactory = EventAnswerApiFactory;
/**
 * EventAnswerApi - object-oriented interface
 * @export
 * @class EventAnswerApi
 * @extends {BaseAPI}
 */
var EventAnswerApi = /** @class */ (function (_super) {
    __extends(EventAnswerApi, _super);
    function EventAnswerApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Create event answer
     * @param {EventAnswerInput} [eventAnswerInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventAnswerApi
     */
    EventAnswerApi.prototype.createEventAnswer = function (eventAnswerInput, options) {
        var _this = this;
        return (0, exports.EventAnswerApiFp)(this.configuration).createEventAnswer(eventAnswerInput, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary List event answer
     * @param {string} eventId
     * @param {string} questionId
     * @param {InputListEventAnswer} [inputListEventAnswer]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventAnswerApi
     */
    EventAnswerApi.prototype.listEventAnswer = function (eventId, questionId, inputListEventAnswer, options) {
        var _this = this;
        return (0, exports.EventAnswerApiFp)(this.configuration).listEventAnswer(eventId, questionId, inputListEventAnswer, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return EventAnswerApi;
}(base_1.BaseAPI));
exports.EventAnswerApi = EventAnswerApi;
/**
 * EventQuestionApi - axios parameter creator
 * @export
 */
var EventQuestionApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Create event question
         * @param {EventQuestionInput} [eventQuestionInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEventQuestion: function (eventQuestionInput, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/event-question/create";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(eventQuestionInput, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Edit event question
         * @param {string} id
         * @param {EventQuestionInput} [eventQuestionInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editEventQuestion: function (id, eventQuestionInput, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('editEventQuestion', 'id', id);
                            localVarPath = "/event-question/{id}/edit"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(eventQuestionInput, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary List event question
         * @param {string} eventId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEventQuestion: function (eventId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'eventId' is not null or undefined
                            (0, common_1.assertParamExists)('listEventQuestion', 'eventId', eventId);
                            localVarPath = "/event-question/event/{eventId}/list"
                                .replace("{".concat("eventId", "}"), encodeURIComponent(String(eventId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary List public event question
         * @param {string} eventId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPublicEventQuestion: function (eventId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'eventId' is not null or undefined
                            (0, common_1.assertParamExists)('listPublicEventQuestion', 'eventId', eventId);
                            localVarPath = "/event-question/event/{eventId}/public-list"
                                .replace("{".concat("eventId", "}"), encodeURIComponent(String(eventId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Single event question
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        singleEventQuestion: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('singleEventQuestion', 'id', id);
                            localVarPath = "/event-question/{id}/"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.EventQuestionApiAxiosParamCreator = EventQuestionApiAxiosParamCreator;
/**
 * EventQuestionApi - functional programming interface
 * @export
 */
var EventQuestionApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.EventQuestionApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Create event question
         * @param {EventQuestionInput} [eventQuestionInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEventQuestion: function (eventQuestionInput, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createEventQuestion(eventQuestionInput, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Edit event question
         * @param {string} id
         * @param {EventQuestionInput} [eventQuestionInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editEventQuestion: function (id, eventQuestionInput, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.editEventQuestion(id, eventQuestionInput, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary List event question
         * @param {string} eventId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEventQuestion: function (eventId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listEventQuestion(eventId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary List public event question
         * @param {string} eventId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPublicEventQuestion: function (eventId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listPublicEventQuestion(eventId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Single event question
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        singleEventQuestion: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.singleEventQuestion(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.EventQuestionApiFp = EventQuestionApiFp;
/**
 * EventQuestionApi - factory interface
 * @export
 */
var EventQuestionApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.EventQuestionApiFp)(configuration);
    return {
        /**
         *
         * @summary Create event question
         * @param {EventQuestionInput} [eventQuestionInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEventQuestion: function (eventQuestionInput, options) {
            return localVarFp.createEventQuestion(eventQuestionInput, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Edit event question
         * @param {string} id
         * @param {EventQuestionInput} [eventQuestionInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editEventQuestion: function (id, eventQuestionInput, options) {
            return localVarFp.editEventQuestion(id, eventQuestionInput, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary List event question
         * @param {string} eventId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEventQuestion: function (eventId, options) {
            return localVarFp.listEventQuestion(eventId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary List public event question
         * @param {string} eventId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPublicEventQuestion: function (eventId, options) {
            return localVarFp.listPublicEventQuestion(eventId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Single event question
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        singleEventQuestion: function (id, options) {
            return localVarFp.singleEventQuestion(id, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.EventQuestionApiFactory = EventQuestionApiFactory;
/**
 * EventQuestionApi - object-oriented interface
 * @export
 * @class EventQuestionApi
 * @extends {BaseAPI}
 */
var EventQuestionApi = /** @class */ (function (_super) {
    __extends(EventQuestionApi, _super);
    function EventQuestionApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Create event question
     * @param {EventQuestionInput} [eventQuestionInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventQuestionApi
     */
    EventQuestionApi.prototype.createEventQuestion = function (eventQuestionInput, options) {
        var _this = this;
        return (0, exports.EventQuestionApiFp)(this.configuration).createEventQuestion(eventQuestionInput, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Edit event question
     * @param {string} id
     * @param {EventQuestionInput} [eventQuestionInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventQuestionApi
     */
    EventQuestionApi.prototype.editEventQuestion = function (id, eventQuestionInput, options) {
        var _this = this;
        return (0, exports.EventQuestionApiFp)(this.configuration).editEventQuestion(id, eventQuestionInput, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary List event question
     * @param {string} eventId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventQuestionApi
     */
    EventQuestionApi.prototype.listEventQuestion = function (eventId, options) {
        var _this = this;
        return (0, exports.EventQuestionApiFp)(this.configuration).listEventQuestion(eventId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary List public event question
     * @param {string} eventId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventQuestionApi
     */
    EventQuestionApi.prototype.listPublicEventQuestion = function (eventId, options) {
        var _this = this;
        return (0, exports.EventQuestionApiFp)(this.configuration).listPublicEventQuestion(eventId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Single event question
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventQuestionApi
     */
    EventQuestionApi.prototype.singleEventQuestion = function (id, options) {
        var _this = this;
        return (0, exports.EventQuestionApiFp)(this.configuration).singleEventQuestion(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return EventQuestionApi;
}(base_1.BaseAPI));
exports.EventQuestionApi = EventQuestionApi;
/**
 * GoogleMapsApi - axios parameter creator
 * @export
 */
var GoogleMapsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Get geocode from place id
         * @param {string} [placeId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeocodeFromPlaceId: function (placeId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/gmaps/geocode";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            if (placeId !== undefined) {
                                localVarQueryParameter['placeId'] = placeId;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get suggestion from google maps
         * @param {string} [q]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuggestionFromGoogleMaps: function (q, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/gmaps/suggestion";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            if (q !== undefined) {
                                localVarQueryParameter['q'] = q;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.GoogleMapsApiAxiosParamCreator = GoogleMapsApiAxiosParamCreator;
/**
 * GoogleMapsApi - functional programming interface
 * @export
 */
var GoogleMapsApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.GoogleMapsApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Get geocode from place id
         * @param {string} [placeId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeocodeFromPlaceId: function (placeId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getGeocodeFromPlaceId(placeId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get suggestion from google maps
         * @param {string} [q]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuggestionFromGoogleMaps: function (q, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getSuggestionFromGoogleMaps(q, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.GoogleMapsApiFp = GoogleMapsApiFp;
/**
 * GoogleMapsApi - factory interface
 * @export
 */
var GoogleMapsApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.GoogleMapsApiFp)(configuration);
    return {
        /**
         *
         * @summary Get geocode from place id
         * @param {string} [placeId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeocodeFromPlaceId: function (placeId, options) {
            return localVarFp.getGeocodeFromPlaceId(placeId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get suggestion from google maps
         * @param {string} [q]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuggestionFromGoogleMaps: function (q, options) {
            return localVarFp.getSuggestionFromGoogleMaps(q, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.GoogleMapsApiFactory = GoogleMapsApiFactory;
/**
 * GoogleMapsApi - object-oriented interface
 * @export
 * @class GoogleMapsApi
 * @extends {BaseAPI}
 */
var GoogleMapsApi = /** @class */ (function (_super) {
    __extends(GoogleMapsApi, _super);
    function GoogleMapsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Get geocode from place id
     * @param {string} [placeId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoogleMapsApi
     */
    GoogleMapsApi.prototype.getGeocodeFromPlaceId = function (placeId, options) {
        var _this = this;
        return (0, exports.GoogleMapsApiFp)(this.configuration).getGeocodeFromPlaceId(placeId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get suggestion from google maps
     * @param {string} [q]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoogleMapsApi
     */
    GoogleMapsApi.prototype.getSuggestionFromGoogleMaps = function (q, options) {
        var _this = this;
        return (0, exports.GoogleMapsApiFp)(this.configuration).getSuggestionFromGoogleMaps(q, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return GoogleMapsApi;
}(base_1.BaseAPI));
exports.GoogleMapsApi = GoogleMapsApi;
/**
 * HealthCheckApi - axios parameter creator
 * @export
 */
var HealthCheckApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Health-check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/health-check/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.HealthCheckApiAxiosParamCreator = HealthCheckApiAxiosParamCreator;
/**
 * HealthCheckApi - functional programming interface
 * @export
 */
var HealthCheckApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.HealthCheckApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Health-check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.healthCheck(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.HealthCheckApiFp = HealthCheckApiFp;
/**
 * HealthCheckApi - factory interface
 * @export
 */
var HealthCheckApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.HealthCheckApiFp)(configuration);
    return {
        /**
         *
         * @summary Health-check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck: function (options) {
            return localVarFp.healthCheck(options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.HealthCheckApiFactory = HealthCheckApiFactory;
/**
 * HealthCheckApi - object-oriented interface
 * @export
 * @class HealthCheckApi
 * @extends {BaseAPI}
 */
var HealthCheckApi = /** @class */ (function (_super) {
    __extends(HealthCheckApi, _super);
    function HealthCheckApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Health-check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthCheckApi
     */
    HealthCheckApi.prototype.healthCheck = function (options) {
        var _this = this;
        return (0, exports.HealthCheckApiFp)(this.configuration).healthCheck(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return HealthCheckApi;
}(base_1.BaseAPI));
exports.HealthCheckApi = HealthCheckApi;
/**
 * HostSoldApi - axios parameter creator
 * @export
 */
var HostSoldApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Get all stats by period of time
         * @param {string} eventId
         * @param {string} [date]
         * @param {string} [token]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSoldForHostByEvent: function (eventId, date, token, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'eventId' is not null or undefined
                            (0, common_1.assertParamExists)('downloadSoldForHostByEvent', 'eventId', eventId);
                            localVarPath = "/sold/{eventId}/download"
                                .replace("{".concat("eventId", "}"), encodeURIComponent(String(eventId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            if (date !== undefined) {
                                localVarQueryParameter['date'] = date;
                            }
                            if (token !== undefined) {
                                localVarQueryParameter['token'] = token;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get all stats by period of time
         * @param {string} eventId
         * @param {string} ticketId
         * @param {string} [date]
         * @param {number} [page]
         * @param {number} [size]
         * @param {'all' | 'true' | 'false'} [filterValidated]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListPurchasedTicketsByEventAndTicketId: function (eventId, ticketId, date, page, size, filterValidated, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'eventId' is not null or undefined
                            (0, common_1.assertParamExists)('getListPurchasedTicketsByEventAndTicketId', 'eventId', eventId);
                            // verify required parameter 'ticketId' is not null or undefined
                            (0, common_1.assertParamExists)('getListPurchasedTicketsByEventAndTicketId', 'ticketId', ticketId);
                            localVarPath = "/sold/{eventId}/{ticketId}"
                                .replace("{".concat("eventId", "}"), encodeURIComponent(String(eventId)))
                                .replace("{".concat("ticketId", "}"), encodeURIComponent(String(ticketId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            if (date !== undefined) {
                                localVarQueryParameter['date'] = date;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (size !== undefined) {
                                localVarQueryParameter['size'] = size;
                            }
                            if (filterValidated !== undefined) {
                                localVarQueryParameter['filterValidated'] = filterValidated;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get all stats by period of time
         * @param {string} eventId
         * @param {string} [date]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSoldForHostByEvent: function (eventId, date, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'eventId' is not null or undefined
                            (0, common_1.assertParamExists)('getSoldForHostByEvent', 'eventId', eventId);
                            localVarPath = "/sold/{eventId}/"
                                .replace("{".concat("eventId", "}"), encodeURIComponent(String(eventId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            if (date !== undefined) {
                                localVarQueryParameter['date'] = date;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.HostSoldApiAxiosParamCreator = HostSoldApiAxiosParamCreator;
/**
 * HostSoldApi - functional programming interface
 * @export
 */
var HostSoldApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.HostSoldApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Get all stats by period of time
         * @param {string} eventId
         * @param {string} [date]
         * @param {string} [token]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSoldForHostByEvent: function (eventId, date, token, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.downloadSoldForHostByEvent(eventId, date, token, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get all stats by period of time
         * @param {string} eventId
         * @param {string} ticketId
         * @param {string} [date]
         * @param {number} [page]
         * @param {number} [size]
         * @param {'all' | 'true' | 'false'} [filterValidated]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListPurchasedTicketsByEventAndTicketId: function (eventId, ticketId, date, page, size, filterValidated, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getListPurchasedTicketsByEventAndTicketId(eventId, ticketId, date, page, size, filterValidated, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get all stats by period of time
         * @param {string} eventId
         * @param {string} [date]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSoldForHostByEvent: function (eventId, date, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getSoldForHostByEvent(eventId, date, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.HostSoldApiFp = HostSoldApiFp;
/**
 * HostSoldApi - factory interface
 * @export
 */
var HostSoldApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.HostSoldApiFp)(configuration);
    return {
        /**
         *
         * @summary Get all stats by period of time
         * @param {string} eventId
         * @param {string} [date]
         * @param {string} [token]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSoldForHostByEvent: function (eventId, date, token, options) {
            return localVarFp.downloadSoldForHostByEvent(eventId, date, token, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get all stats by period of time
         * @param {string} eventId
         * @param {string} ticketId
         * @param {string} [date]
         * @param {number} [page]
         * @param {number} [size]
         * @param {'all' | 'true' | 'false'} [filterValidated]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListPurchasedTicketsByEventAndTicketId: function (eventId, ticketId, date, page, size, filterValidated, options) {
            return localVarFp.getListPurchasedTicketsByEventAndTicketId(eventId, ticketId, date, page, size, filterValidated, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get all stats by period of time
         * @param {string} eventId
         * @param {string} [date]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSoldForHostByEvent: function (eventId, date, options) {
            return localVarFp.getSoldForHostByEvent(eventId, date, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.HostSoldApiFactory = HostSoldApiFactory;
/**
 * HostSoldApi - object-oriented interface
 * @export
 * @class HostSoldApi
 * @extends {BaseAPI}
 */
var HostSoldApi = /** @class */ (function (_super) {
    __extends(HostSoldApi, _super);
    function HostSoldApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Get all stats by period of time
     * @param {string} eventId
     * @param {string} [date]
     * @param {string} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HostSoldApi
     */
    HostSoldApi.prototype.downloadSoldForHostByEvent = function (eventId, date, token, options) {
        var _this = this;
        return (0, exports.HostSoldApiFp)(this.configuration).downloadSoldForHostByEvent(eventId, date, token, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get all stats by period of time
     * @param {string} eventId
     * @param {string} ticketId
     * @param {string} [date]
     * @param {number} [page]
     * @param {number} [size]
     * @param {'all' | 'true' | 'false'} [filterValidated]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HostSoldApi
     */
    HostSoldApi.prototype.getListPurchasedTicketsByEventAndTicketId = function (eventId, ticketId, date, page, size, filterValidated, options) {
        var _this = this;
        return (0, exports.HostSoldApiFp)(this.configuration).getListPurchasedTicketsByEventAndTicketId(eventId, ticketId, date, page, size, filterValidated, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get all stats by period of time
     * @param {string} eventId
     * @param {string} [date]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HostSoldApi
     */
    HostSoldApi.prototype.getSoldForHostByEvent = function (eventId, date, options) {
        var _this = this;
        return (0, exports.HostSoldApiFp)(this.configuration).getSoldForHostByEvent(eventId, date, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return HostSoldApi;
}(base_1.BaseAPI));
exports.HostSoldApi = HostSoldApi;
/**
 * HostStatsApi - axios parameter creator
 * @export
 */
var HostStatsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Get all stats by period of time
         * @param {string} beginDate
         * @param {string} endDate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatsForHostByPeriod: function (beginDate, endDate, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'beginDate' is not null or undefined
                            (0, common_1.assertParamExists)('getStatsForHostByPeriod', 'beginDate', beginDate);
                            // verify required parameter 'endDate' is not null or undefined
                            (0, common_1.assertParamExists)('getStatsForHostByPeriod', 'endDate', endDate);
                            localVarPath = "/stats/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            if (beginDate !== undefined) {
                                localVarQueryParameter['begin-date'] = beginDate;
                            }
                            if (endDate !== undefined) {
                                localVarQueryParameter['end-date'] = endDate;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.HostStatsApiAxiosParamCreator = HostStatsApiAxiosParamCreator;
/**
 * HostStatsApi - functional programming interface
 * @export
 */
var HostStatsApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.HostStatsApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Get all stats by period of time
         * @param {string} beginDate
         * @param {string} endDate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatsForHostByPeriod: function (beginDate, endDate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getStatsForHostByPeriod(beginDate, endDate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.HostStatsApiFp = HostStatsApiFp;
/**
 * HostStatsApi - factory interface
 * @export
 */
var HostStatsApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.HostStatsApiFp)(configuration);
    return {
        /**
         *
         * @summary Get all stats by period of time
         * @param {string} beginDate
         * @param {string} endDate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatsForHostByPeriod: function (beginDate, endDate, options) {
            return localVarFp.getStatsForHostByPeriod(beginDate, endDate, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.HostStatsApiFactory = HostStatsApiFactory;
/**
 * HostStatsApi - object-oriented interface
 * @export
 * @class HostStatsApi
 * @extends {BaseAPI}
 */
var HostStatsApi = /** @class */ (function (_super) {
    __extends(HostStatsApi, _super);
    function HostStatsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Get all stats by period of time
     * @param {string} beginDate
     * @param {string} endDate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HostStatsApi
     */
    HostStatsApi.prototype.getStatsForHostByPeriod = function (beginDate, endDate, options) {
        var _this = this;
        return (0, exports.HostStatsApiFp)(this.configuration).getStatsForHostByPeriod(beginDate, endDate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return HostStatsApi;
}(base_1.BaseAPI));
exports.HostStatsApi = HostStatsApi;
/**
 * InputModelsApi - axios parameter creator
 * @export
 */
var InputModelsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary List input models
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInputModels: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/input-models";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.InputModelsApiAxiosParamCreator = InputModelsApiAxiosParamCreator;
/**
 * InputModelsApi - functional programming interface
 * @export
 */
var InputModelsApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.InputModelsApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary List input models
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInputModels: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listInputModels(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.InputModelsApiFp = InputModelsApiFp;
/**
 * InputModelsApi - factory interface
 * @export
 */
var InputModelsApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.InputModelsApiFp)(configuration);
    return {
        /**
         *
         * @summary List input models
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInputModels: function (options) {
            return localVarFp.listInputModels(options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.InputModelsApiFactory = InputModelsApiFactory;
/**
 * InputModelsApi - object-oriented interface
 * @export
 * @class InputModelsApi
 * @extends {BaseAPI}
 */
var InputModelsApi = /** @class */ (function (_super) {
    __extends(InputModelsApi, _super);
    function InputModelsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary List input models
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputModelsApi
     */
    InputModelsApi.prototype.listInputModels = function (options) {
        var _this = this;
        return (0, exports.InputModelsApiFp)(this.configuration).listInputModels(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return InputModelsApi;
}(base_1.BaseAPI));
exports.InputModelsApi = InputModelsApi;
/**
 * InvoiceApi - axios parameter creator
 * @export
 */
var InvoiceApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * List all available years
         * @summary List available years
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAvailableYears: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/invoices/years";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * List invoices of user
         * @summary List invoices of user
         * @param {number} [year]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMyInvoices: function (year, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/invoices/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            if (year !== undefined) {
                                localVarQueryParameter['year'] = year;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.InvoiceApiAxiosParamCreator = InvoiceApiAxiosParamCreator;
/**
 * InvoiceApi - functional programming interface
 * @export
 */
var InvoiceApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.InvoiceApiAxiosParamCreator)(configuration);
    return {
        /**
         * List all available years
         * @summary List available years
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAvailableYears: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listAvailableYears(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * List invoices of user
         * @summary List invoices of user
         * @param {number} [year]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMyInvoices: function (year, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listMyInvoices(year, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.InvoiceApiFp = InvoiceApiFp;
/**
 * InvoiceApi - factory interface
 * @export
 */
var InvoiceApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.InvoiceApiFp)(configuration);
    return {
        /**
         * List all available years
         * @summary List available years
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAvailableYears: function (options) {
            return localVarFp.listAvailableYears(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * List invoices of user
         * @summary List invoices of user
         * @param {number} [year]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMyInvoices: function (year, options) {
            return localVarFp.listMyInvoices(year, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.InvoiceApiFactory = InvoiceApiFactory;
/**
 * InvoiceApi - object-oriented interface
 * @export
 * @class InvoiceApi
 * @extends {BaseAPI}
 */
var InvoiceApi = /** @class */ (function (_super) {
    __extends(InvoiceApi, _super);
    function InvoiceApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * List all available years
     * @summary List available years
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    InvoiceApi.prototype.listAvailableYears = function (options) {
        var _this = this;
        return (0, exports.InvoiceApiFp)(this.configuration).listAvailableYears(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * List invoices of user
     * @summary List invoices of user
     * @param {number} [year]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    InvoiceApi.prototype.listMyInvoices = function (year, options) {
        var _this = this;
        return (0, exports.InvoiceApiFp)(this.configuration).listMyInvoices(year, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return InvoiceApi;
}(base_1.BaseAPI));
exports.InvoiceApi = InvoiceApi;
/**
 * MangoKycDocumentApi - axios parameter creator
 * @export
 */
var MangoKycDocumentApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Create mango k y c document
         * @param {Array<any>} [files]
         * @param {string} [type]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMangoKYCDocument: function (files, type, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/mango-pay/host/kyc";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            if (files) {
                                files.forEach(function (element) {
                                    localVarFormParams.append('files', element);
                                });
                            }
                            if (type !== undefined) {
                                localVarFormParams.append('type', type);
                            }
                            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = localVarFormParams;
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.MangoKycDocumentApiAxiosParamCreator = MangoKycDocumentApiAxiosParamCreator;
/**
 * MangoKycDocumentApi - functional programming interface
 * @export
 */
var MangoKycDocumentApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.MangoKycDocumentApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Create mango k y c document
         * @param {Array<any>} [files]
         * @param {string} [type]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMangoKYCDocument: function (files, type, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createMangoKYCDocument(files, type, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.MangoKycDocumentApiFp = MangoKycDocumentApiFp;
/**
 * MangoKycDocumentApi - factory interface
 * @export
 */
var MangoKycDocumentApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.MangoKycDocumentApiFp)(configuration);
    return {
        /**
         *
         * @summary Create mango k y c document
         * @param {Array<any>} [files]
         * @param {string} [type]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMangoKYCDocument: function (files, type, options) {
            return localVarFp.createMangoKYCDocument(files, type, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.MangoKycDocumentApiFactory = MangoKycDocumentApiFactory;
/**
 * MangoKycDocumentApi - object-oriented interface
 * @export
 * @class MangoKycDocumentApi
 * @extends {BaseAPI}
 */
var MangoKycDocumentApi = /** @class */ (function (_super) {
    __extends(MangoKycDocumentApi, _super);
    function MangoKycDocumentApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Create mango k y c document
     * @param {Array<any>} [files]
     * @param {string} [type]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MangoKycDocumentApi
     */
    MangoKycDocumentApi.prototype.createMangoKYCDocument = function (files, type, options) {
        var _this = this;
        return (0, exports.MangoKycDocumentApiFp)(this.configuration).createMangoKYCDocument(files, type, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return MangoKycDocumentApi;
}(base_1.BaseAPI));
exports.MangoKycDocumentApi = MangoKycDocumentApi;
/**
 * MangoPayApi - axios parameter creator
 * @export
 */
var MangoPayApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary it creates a mango bank account
         * @param {InputCreateMangoBankAccount} [inputCreateMangoBankAccount]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMangoBankAccount: function (inputCreateMangoBankAccount, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/mango-pay/host/bank-account";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputCreateMangoBankAccount, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary it creates a mango host or update it
         * @param {InputCreateOrUpdateMangoHost} [inputCreateOrUpdateMangoHost]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateMangoHost: function (inputCreateOrUpdateMangoHost, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/mango-pay/host/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputCreateOrUpdateMangoHost, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary it creates a mango user or update it
         * @param {InputCreateOrUpdateMangoUser} [inputCreateOrUpdateMangoUser]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateMangoUser: function (inputCreateOrUpdateMangoUser, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/mango-pay/user/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputCreateOrUpdateMangoUser, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get cards list
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCardsList: function (filter, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/mango-pay/card/list";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            if (filter !== undefined) {
                                localVarQueryParameter['filter'] = filter;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary get configuration for mango host
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMangoConfiguration: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/mango-pay/configuration/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary get mango host for current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMangoHost: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/mango-pay/host/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary get mango user for current user
         * @param {string} [mangoUserId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMangoUser: function (mangoUserId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/mango-pay/user/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            if (mangoUserId !== undefined) {
                                localVarQueryParameter['mangoUserId'] = mangoUserId;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Register mango card
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerMangoCard: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/mango-pay/card/profile/register";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Remove card
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeCard: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('removeCard', 'id', id);
                            localVarPath = "/mango-pay/card/{id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Request card registration
         * @param {InputRequestCardRegistration} [inputRequestCardRegistration]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestCardRegistration: function (inputRequestCardRegistration, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/mango-pay/card/registration";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputRequestCardRegistration, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.MangoPayApiAxiosParamCreator = MangoPayApiAxiosParamCreator;
/**
 * MangoPayApi - functional programming interface
 * @export
 */
var MangoPayApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.MangoPayApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary it creates a mango bank account
         * @param {InputCreateMangoBankAccount} [inputCreateMangoBankAccount]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMangoBankAccount: function (inputCreateMangoBankAccount, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createMangoBankAccount(inputCreateMangoBankAccount, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary it creates a mango host or update it
         * @param {InputCreateOrUpdateMangoHost} [inputCreateOrUpdateMangoHost]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateMangoHost: function (inputCreateOrUpdateMangoHost, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createOrUpdateMangoHost(inputCreateOrUpdateMangoHost, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary it creates a mango user or update it
         * @param {InputCreateOrUpdateMangoUser} [inputCreateOrUpdateMangoUser]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateMangoUser: function (inputCreateOrUpdateMangoUser, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createOrUpdateMangoUser(inputCreateOrUpdateMangoUser, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get cards list
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCardsList: function (filter, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getCardsList(filter, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary get configuration for mango host
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMangoConfiguration: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getMangoConfiguration(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary get mango host for current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMangoHost: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getMangoHost(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary get mango user for current user
         * @param {string} [mangoUserId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMangoUser: function (mangoUserId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getMangoUser(mangoUserId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Register mango card
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerMangoCard: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.registerMangoCard(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Remove card
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeCard: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.removeCard(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Request card registration
         * @param {InputRequestCardRegistration} [inputRequestCardRegistration]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestCardRegistration: function (inputRequestCardRegistration, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.requestCardRegistration(inputRequestCardRegistration, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.MangoPayApiFp = MangoPayApiFp;
/**
 * MangoPayApi - factory interface
 * @export
 */
var MangoPayApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.MangoPayApiFp)(configuration);
    return {
        /**
         *
         * @summary it creates a mango bank account
         * @param {InputCreateMangoBankAccount} [inputCreateMangoBankAccount]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMangoBankAccount: function (inputCreateMangoBankAccount, options) {
            return localVarFp.createMangoBankAccount(inputCreateMangoBankAccount, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary it creates a mango host or update it
         * @param {InputCreateOrUpdateMangoHost} [inputCreateOrUpdateMangoHost]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateMangoHost: function (inputCreateOrUpdateMangoHost, options) {
            return localVarFp.createOrUpdateMangoHost(inputCreateOrUpdateMangoHost, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary it creates a mango user or update it
         * @param {InputCreateOrUpdateMangoUser} [inputCreateOrUpdateMangoUser]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateMangoUser: function (inputCreateOrUpdateMangoUser, options) {
            return localVarFp.createOrUpdateMangoUser(inputCreateOrUpdateMangoUser, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get cards list
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCardsList: function (filter, options) {
            return localVarFp.getCardsList(filter, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary get configuration for mango host
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMangoConfiguration: function (options) {
            return localVarFp.getMangoConfiguration(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary get mango host for current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMangoHost: function (options) {
            return localVarFp.getMangoHost(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary get mango user for current user
         * @param {string} [mangoUserId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMangoUser: function (mangoUserId, options) {
            return localVarFp.getMangoUser(mangoUserId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Register mango card
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerMangoCard: function (options) {
            return localVarFp.registerMangoCard(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Remove card
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeCard: function (id, options) {
            return localVarFp.removeCard(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Request card registration
         * @param {InputRequestCardRegistration} [inputRequestCardRegistration]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestCardRegistration: function (inputRequestCardRegistration, options) {
            return localVarFp.requestCardRegistration(inputRequestCardRegistration, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.MangoPayApiFactory = MangoPayApiFactory;
/**
 * MangoPayApi - object-oriented interface
 * @export
 * @class MangoPayApi
 * @extends {BaseAPI}
 */
var MangoPayApi = /** @class */ (function (_super) {
    __extends(MangoPayApi, _super);
    function MangoPayApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary it creates a mango bank account
     * @param {InputCreateMangoBankAccount} [inputCreateMangoBankAccount]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MangoPayApi
     */
    MangoPayApi.prototype.createMangoBankAccount = function (inputCreateMangoBankAccount, options) {
        var _this = this;
        return (0, exports.MangoPayApiFp)(this.configuration).createMangoBankAccount(inputCreateMangoBankAccount, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary it creates a mango host or update it
     * @param {InputCreateOrUpdateMangoHost} [inputCreateOrUpdateMangoHost]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MangoPayApi
     */
    MangoPayApi.prototype.createOrUpdateMangoHost = function (inputCreateOrUpdateMangoHost, options) {
        var _this = this;
        return (0, exports.MangoPayApiFp)(this.configuration).createOrUpdateMangoHost(inputCreateOrUpdateMangoHost, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary it creates a mango user or update it
     * @param {InputCreateOrUpdateMangoUser} [inputCreateOrUpdateMangoUser]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MangoPayApi
     */
    MangoPayApi.prototype.createOrUpdateMangoUser = function (inputCreateOrUpdateMangoUser, options) {
        var _this = this;
        return (0, exports.MangoPayApiFp)(this.configuration).createOrUpdateMangoUser(inputCreateOrUpdateMangoUser, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get cards list
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MangoPayApi
     */
    MangoPayApi.prototype.getCardsList = function (filter, options) {
        var _this = this;
        return (0, exports.MangoPayApiFp)(this.configuration).getCardsList(filter, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary get configuration for mango host
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MangoPayApi
     */
    MangoPayApi.prototype.getMangoConfiguration = function (options) {
        var _this = this;
        return (0, exports.MangoPayApiFp)(this.configuration).getMangoConfiguration(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary get mango host for current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MangoPayApi
     */
    MangoPayApi.prototype.getMangoHost = function (options) {
        var _this = this;
        return (0, exports.MangoPayApiFp)(this.configuration).getMangoHost(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary get mango user for current user
     * @param {string} [mangoUserId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MangoPayApi
     */
    MangoPayApi.prototype.getMangoUser = function (mangoUserId, options) {
        var _this = this;
        return (0, exports.MangoPayApiFp)(this.configuration).getMangoUser(mangoUserId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Register mango card
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MangoPayApi
     */
    MangoPayApi.prototype.registerMangoCard = function (options) {
        var _this = this;
        return (0, exports.MangoPayApiFp)(this.configuration).registerMangoCard(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Remove card
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MangoPayApi
     */
    MangoPayApi.prototype.removeCard = function (id, options) {
        var _this = this;
        return (0, exports.MangoPayApiFp)(this.configuration).removeCard(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Request card registration
     * @param {InputRequestCardRegistration} [inputRequestCardRegistration]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MangoPayApi
     */
    MangoPayApi.prototype.requestCardRegistration = function (inputRequestCardRegistration, options) {
        var _this = this;
        return (0, exports.MangoPayApiFp)(this.configuration).requestCardRegistration(inputRequestCardRegistration, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return MangoPayApi;
}(base_1.BaseAPI));
exports.MangoPayApi = MangoPayApi;
/**
 * MangoUboDeclarationApi - axios parameter creator
 * @export
 */
var MangoUboDeclarationApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Save ubo declaration
         * @param {Array<UboDeclaration>} [requestBody]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUboDeclaration: function (requestBody, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/mango-pay/host/saveUbo";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(requestBody, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Send for verification
         * @param {InputSendForVerification} [inputSendForVerification]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendForVerification: function (inputSendForVerification, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/mango-pay/host/sendForVerificationUbo";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputSendForVerification, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.MangoUboDeclarationApiAxiosParamCreator = MangoUboDeclarationApiAxiosParamCreator;
/**
 * MangoUboDeclarationApi - functional programming interface
 * @export
 */
var MangoUboDeclarationApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.MangoUboDeclarationApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Save ubo declaration
         * @param {Array<UboDeclaration>} [requestBody]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUboDeclaration: function (requestBody, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.saveUboDeclaration(requestBody, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Send for verification
         * @param {InputSendForVerification} [inputSendForVerification]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendForVerification: function (inputSendForVerification, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.sendForVerification(inputSendForVerification, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.MangoUboDeclarationApiFp = MangoUboDeclarationApiFp;
/**
 * MangoUboDeclarationApi - factory interface
 * @export
 */
var MangoUboDeclarationApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.MangoUboDeclarationApiFp)(configuration);
    return {
        /**
         *
         * @summary Save ubo declaration
         * @param {Array<UboDeclaration>} [requestBody]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUboDeclaration: function (requestBody, options) {
            return localVarFp.saveUboDeclaration(requestBody, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Send for verification
         * @param {InputSendForVerification} [inputSendForVerification]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendForVerification: function (inputSendForVerification, options) {
            return localVarFp.sendForVerification(inputSendForVerification, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.MangoUboDeclarationApiFactory = MangoUboDeclarationApiFactory;
/**
 * MangoUboDeclarationApi - object-oriented interface
 * @export
 * @class MangoUboDeclarationApi
 * @extends {BaseAPI}
 */
var MangoUboDeclarationApi = /** @class */ (function (_super) {
    __extends(MangoUboDeclarationApi, _super);
    function MangoUboDeclarationApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Save ubo declaration
     * @param {Array<UboDeclaration>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MangoUboDeclarationApi
     */
    MangoUboDeclarationApi.prototype.saveUboDeclaration = function (requestBody, options) {
        var _this = this;
        return (0, exports.MangoUboDeclarationApiFp)(this.configuration).saveUboDeclaration(requestBody, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Send for verification
     * @param {InputSendForVerification} [inputSendForVerification]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MangoUboDeclarationApi
     */
    MangoUboDeclarationApi.prototype.sendForVerification = function (inputSendForVerification, options) {
        var _this = this;
        return (0, exports.MangoUboDeclarationApiFp)(this.configuration).sendForVerification(inputSendForVerification, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return MangoUboDeclarationApi;
}(base_1.BaseAPI));
exports.MangoUboDeclarationApi = MangoUboDeclarationApi;
/**
 * OutputModelsApi - axios parameter creator
 * @export
 */
var OutputModelsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary List output models
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOutputModels: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/output-models";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.OutputModelsApiAxiosParamCreator = OutputModelsApiAxiosParamCreator;
/**
 * OutputModelsApi - functional programming interface
 * @export
 */
var OutputModelsApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.OutputModelsApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary List output models
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOutputModels: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listOutputModels(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.OutputModelsApiFp = OutputModelsApiFp;
/**
 * OutputModelsApi - factory interface
 * @export
 */
var OutputModelsApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.OutputModelsApiFp)(configuration);
    return {
        /**
         *
         * @summary List output models
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOutputModels: function (options) {
            return localVarFp.listOutputModels(options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.OutputModelsApiFactory = OutputModelsApiFactory;
/**
 * OutputModelsApi - object-oriented interface
 * @export
 * @class OutputModelsApi
 * @extends {BaseAPI}
 */
var OutputModelsApi = /** @class */ (function (_super) {
    __extends(OutputModelsApi, _super);
    function OutputModelsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary List output models
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutputModelsApi
     */
    OutputModelsApi.prototype.listOutputModels = function (options) {
        var _this = this;
        return (0, exports.OutputModelsApiFp)(this.configuration).listOutputModels(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return OutputModelsApi;
}(base_1.BaseAPI));
exports.OutputModelsApi = OutputModelsApi;
/**
 * ProvincesApi - axios parameter creator
 * @export
 */
var ProvincesApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary get a list of provinces
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProvinces: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/provinces/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.ProvincesApiAxiosParamCreator = ProvincesApiAxiosParamCreator;
/**
 * ProvincesApi - functional programming interface
 * @export
 */
var ProvincesApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.ProvincesApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary get a list of provinces
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProvinces: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listProvinces(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.ProvincesApiFp = ProvincesApiFp;
/**
 * ProvincesApi - factory interface
 * @export
 */
var ProvincesApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.ProvincesApiFp)(configuration);
    return {
        /**
         *
         * @summary get a list of provinces
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProvinces: function (options) {
            return localVarFp.listProvinces(options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.ProvincesApiFactory = ProvincesApiFactory;
/**
 * ProvincesApi - object-oriented interface
 * @export
 * @class ProvincesApi
 * @extends {BaseAPI}
 */
var ProvincesApi = /** @class */ (function (_super) {
    __extends(ProvincesApi, _super);
    function ProvincesApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary get a list of provinces
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvincesApi
     */
    ProvincesApi.prototype.listProvinces = function (options) {
        var _this = this;
        return (0, exports.ProvincesApiFp)(this.configuration).listProvinces(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return ProvincesApi;
}(base_1.BaseAPI));
exports.ProvincesApi = ProvincesApi;
/**
 * ProxyGmapsApi - axios parameter creator
 * @export
 */
var ProxyGmapsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Get gmaps script content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGmapsScriptContent: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/proxy/gmaps";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.ProxyGmapsApiAxiosParamCreator = ProxyGmapsApiAxiosParamCreator;
/**
 * ProxyGmapsApi - functional programming interface
 * @export
 */
var ProxyGmapsApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.ProxyGmapsApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Get gmaps script content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGmapsScriptContent: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getGmapsScriptContent(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.ProxyGmapsApiFp = ProxyGmapsApiFp;
/**
 * ProxyGmapsApi - factory interface
 * @export
 */
var ProxyGmapsApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.ProxyGmapsApiFp)(configuration);
    return {
        /**
         *
         * @summary Get gmaps script content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGmapsScriptContent: function (options) {
            return localVarFp.getGmapsScriptContent(options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.ProxyGmapsApiFactory = ProxyGmapsApiFactory;
/**
 * ProxyGmapsApi - object-oriented interface
 * @export
 * @class ProxyGmapsApi
 * @extends {BaseAPI}
 */
var ProxyGmapsApi = /** @class */ (function (_super) {
    __extends(ProxyGmapsApi, _super);
    function ProxyGmapsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Get gmaps script content
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProxyGmapsApi
     */
    ProxyGmapsApi.prototype.getGmapsScriptContent = function (options) {
        var _this = this;
        return (0, exports.ProxyGmapsApiFp)(this.configuration).getGmapsScriptContent(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return ProxyGmapsApi;
}(base_1.BaseAPI));
exports.ProxyGmapsApi = ProxyGmapsApi;
/**
 * PurchaseApi - axios parameter creator
 * @export
 */
var PurchaseApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary ask a refund for a purchase
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        askRefundForPurchaseById: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('askRefundForPurchaseById', 'id', id);
                            localVarPath = "/purchases/{id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary get count of purchases
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countPurchases: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/purchases/count";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary creates new purchase
         * @param {PurchaseInput} [purchaseInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPurchase: function (purchaseInput, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/purchases/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(purchaseInput, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary edit purchased ticket
         * @param {string} id
         * @param {InputEditPurchasedTicket} [inputEditPurchasedTicket]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editPurchasedTicket: function (id, inputEditPurchasedTicket, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('editPurchasedTicket', 'id', id);
                            localVarPath = "/purchases/ticket/{id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputEditPurchasedTicket, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary get all purchases either current and past
         * @param {boolean} [paginate]
         * @param {number} [size]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPurchases: function (paginate, size, page, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/purchases/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            if (paginate !== undefined) {
                                localVarQueryParameter['paginate'] = paginate;
                            }
                            if (size !== undefined) {
                                localVarQueryParameter['size'] = size;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary get all current purchases
         * @param {boolean} [paginate]
         * @param {number} [size]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentPurchases: function (paginate, size, page, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/purchases/current";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            if (paginate !== undefined) {
                                localVarQueryParameter['paginate'] = paginate;
                            }
                            if (size !== undefined) {
                                localVarQueryParameter['size'] = size;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary get all past purchases
         * @param {boolean} [paginate]
         * @param {number} [size]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPastPurchases: function (paginate, size, page, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/purchases/past";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            if (paginate !== undefined) {
                                localVarQueryParameter['paginate'] = paginate;
                            }
                            if (size !== undefined) {
                                localVarQueryParameter['size'] = size;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary get purchase by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPurchaseById: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('getPurchaseById', 'id', id);
                            localVarPath = "/purchases/{id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary get purchase tickets for an event
         * @param {string} eventId
         * @param {string} [date]
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [qry]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPurchaseTicketsByEventIdForOwner: function (eventId, date, page, size, qry, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'eventId' is not null or undefined
                            (0, common_1.assertParamExists)('getPurchaseTicketsByEventIdForOwner', 'eventId', eventId);
                            localVarPath = "/purchases/event/{eventId}/owner"
                                .replace("{".concat("eventId", "}"), encodeURIComponent(String(eventId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            if (date !== undefined) {
                                localVarQueryParameter['date'] = date;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (size !== undefined) {
                                localVarQueryParameter['size'] = size;
                            }
                            if (qry !== undefined) {
                                localVarQueryParameter['qry'] = qry;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get purchases by event
         * @param {string} [page]
         * @param {string} [perPage]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPurchasesByEvent: function (page, perPage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/purchases/by-event/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (perPage !== undefined) {
                                localVarQueryParameter['perPage'] = perPage;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary get all purchases by event and date
         * @param {number} eventId
         * @param {string} [date]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPurchasesByEventAndDate: function (eventId, date, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'eventId' is not null or undefined
                            (0, common_1.assertParamExists)('getPurchasesByEventAndDate', 'eventId', eventId);
                            localVarPath = "/purchases/by-event/and-date";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            if (eventId !== undefined) {
                                localVarQueryParameter['eventId'] = eventId;
                            }
                            if (date !== undefined) {
                                localVarQueryParameter['date'] = (date instanceof Date) ?
                                    date.toISOString().substr(0, 10) :
                                    date;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary get order for an event
         * @param {string} eventId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPurchasesForEventId: function (eventId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'eventId' is not null or undefined
                            (0, common_1.assertParamExists)('getPurchasesForEventId', 'eventId', eventId);
                            localVarPath = "/purchases/event/{eventId}/"
                                .replace("{".concat("eventId", "}"), encodeURIComponent(String(eventId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Revoke share purchase ticket by id
         * @param {string} purchaseTicketId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeSharePurchaseTicketById: function (purchaseTicketId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'purchaseTicketId' is not null or undefined
                            (0, common_1.assertParamExists)('revokeSharePurchaseTicketById', 'purchaseTicketId', purchaseTicketId);
                            localVarPath = "/purchases/ticket/share-with/{purchaseTicketId}"
                                .replace("{".concat("purchaseTicketId", "}"), encodeURIComponent(String(purchaseTicketId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Share purchase ticket by id
         * @param {string} purchaseTicketId
         * @param {InputSharePurchaseTicketById} [inputSharePurchaseTicketById]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharePurchaseTicketById: function (purchaseTicketId, inputSharePurchaseTicketById, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'purchaseTicketId' is not null or undefined
                            (0, common_1.assertParamExists)('sharePurchaseTicketById', 'purchaseTicketId', purchaseTicketId);
                            localVarPath = "/purchases/ticket/share-with/{purchaseTicketId}"
                                .replace("{".concat("purchaseTicketId", "}"), encodeURIComponent(String(purchaseTicketId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputSharePurchaseTicketById, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary validates a ticket
         * @param {string} purchaseTicketId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validatePurchaseTicketById: function (purchaseTicketId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'purchaseTicketId' is not null or undefined
                            (0, common_1.assertParamExists)('validatePurchaseTicketById', 'purchaseTicketId', purchaseTicketId);
                            localVarPath = "/purchases/ticket/validate/{purchaseTicketId}"
                                .replace("{".concat("purchaseTicketId", "}"), encodeURIComponent(String(purchaseTicketId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.PurchaseApiAxiosParamCreator = PurchaseApiAxiosParamCreator;
/**
 * PurchaseApi - functional programming interface
 * @export
 */
var PurchaseApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.PurchaseApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary ask a refund for a purchase
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        askRefundForPurchaseById: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.askRefundForPurchaseById(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary get count of purchases
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countPurchases: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.countPurchases(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary creates new purchase
         * @param {PurchaseInput} [purchaseInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPurchase: function (purchaseInput, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createPurchase(purchaseInput, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary edit purchased ticket
         * @param {string} id
         * @param {InputEditPurchasedTicket} [inputEditPurchasedTicket]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editPurchasedTicket: function (id, inputEditPurchasedTicket, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.editPurchasedTicket(id, inputEditPurchasedTicket, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary get all purchases either current and past
         * @param {boolean} [paginate]
         * @param {number} [size]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPurchases: function (paginate, size, page, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getAllPurchases(paginate, size, page, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary get all current purchases
         * @param {boolean} [paginate]
         * @param {number} [size]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentPurchases: function (paginate, size, page, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getCurrentPurchases(paginate, size, page, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary get all past purchases
         * @param {boolean} [paginate]
         * @param {number} [size]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPastPurchases: function (paginate, size, page, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getPastPurchases(paginate, size, page, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary get purchase by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPurchaseById: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getPurchaseById(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary get purchase tickets for an event
         * @param {string} eventId
         * @param {string} [date]
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [qry]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPurchaseTicketsByEventIdForOwner: function (eventId, date, page, size, qry, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getPurchaseTicketsByEventIdForOwner(eventId, date, page, size, qry, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get purchases by event
         * @param {string} [page]
         * @param {string} [perPage]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPurchasesByEvent: function (page, perPage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getPurchasesByEvent(page, perPage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary get all purchases by event and date
         * @param {number} eventId
         * @param {string} [date]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPurchasesByEventAndDate: function (eventId, date, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getPurchasesByEventAndDate(eventId, date, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary get order for an event
         * @param {string} eventId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPurchasesForEventId: function (eventId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getPurchasesForEventId(eventId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Revoke share purchase ticket by id
         * @param {string} purchaseTicketId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeSharePurchaseTicketById: function (purchaseTicketId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.revokeSharePurchaseTicketById(purchaseTicketId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Share purchase ticket by id
         * @param {string} purchaseTicketId
         * @param {InputSharePurchaseTicketById} [inputSharePurchaseTicketById]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharePurchaseTicketById: function (purchaseTicketId, inputSharePurchaseTicketById, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.sharePurchaseTicketById(purchaseTicketId, inputSharePurchaseTicketById, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary validates a ticket
         * @param {string} purchaseTicketId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validatePurchaseTicketById: function (purchaseTicketId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.validatePurchaseTicketById(purchaseTicketId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.PurchaseApiFp = PurchaseApiFp;
/**
 * PurchaseApi - factory interface
 * @export
 */
var PurchaseApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.PurchaseApiFp)(configuration);
    return {
        /**
         *
         * @summary ask a refund for a purchase
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        askRefundForPurchaseById: function (id, options) {
            return localVarFp.askRefundForPurchaseById(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary get count of purchases
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countPurchases: function (options) {
            return localVarFp.countPurchases(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary creates new purchase
         * @param {PurchaseInput} [purchaseInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPurchase: function (purchaseInput, options) {
            return localVarFp.createPurchase(purchaseInput, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary edit purchased ticket
         * @param {string} id
         * @param {InputEditPurchasedTicket} [inputEditPurchasedTicket]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editPurchasedTicket: function (id, inputEditPurchasedTicket, options) {
            return localVarFp.editPurchasedTicket(id, inputEditPurchasedTicket, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary get all purchases either current and past
         * @param {boolean} [paginate]
         * @param {number} [size]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPurchases: function (paginate, size, page, options) {
            return localVarFp.getAllPurchases(paginate, size, page, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary get all current purchases
         * @param {boolean} [paginate]
         * @param {number} [size]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentPurchases: function (paginate, size, page, options) {
            return localVarFp.getCurrentPurchases(paginate, size, page, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary get all past purchases
         * @param {boolean} [paginate]
         * @param {number} [size]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPastPurchases: function (paginate, size, page, options) {
            return localVarFp.getPastPurchases(paginate, size, page, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary get purchase by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPurchaseById: function (id, options) {
            return localVarFp.getPurchaseById(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary get purchase tickets for an event
         * @param {string} eventId
         * @param {string} [date]
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [qry]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPurchaseTicketsByEventIdForOwner: function (eventId, date, page, size, qry, options) {
            return localVarFp.getPurchaseTicketsByEventIdForOwner(eventId, date, page, size, qry, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get purchases by event
         * @param {string} [page]
         * @param {string} [perPage]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPurchasesByEvent: function (page, perPage, options) {
            return localVarFp.getPurchasesByEvent(page, perPage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary get all purchases by event and date
         * @param {number} eventId
         * @param {string} [date]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPurchasesByEventAndDate: function (eventId, date, options) {
            return localVarFp.getPurchasesByEventAndDate(eventId, date, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary get order for an event
         * @param {string} eventId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPurchasesForEventId: function (eventId, options) {
            return localVarFp.getPurchasesForEventId(eventId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Revoke share purchase ticket by id
         * @param {string} purchaseTicketId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeSharePurchaseTicketById: function (purchaseTicketId, options) {
            return localVarFp.revokeSharePurchaseTicketById(purchaseTicketId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Share purchase ticket by id
         * @param {string} purchaseTicketId
         * @param {InputSharePurchaseTicketById} [inputSharePurchaseTicketById]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharePurchaseTicketById: function (purchaseTicketId, inputSharePurchaseTicketById, options) {
            return localVarFp.sharePurchaseTicketById(purchaseTicketId, inputSharePurchaseTicketById, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary validates a ticket
         * @param {string} purchaseTicketId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validatePurchaseTicketById: function (purchaseTicketId, options) {
            return localVarFp.validatePurchaseTicketById(purchaseTicketId, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.PurchaseApiFactory = PurchaseApiFactory;
/**
 * PurchaseApi - object-oriented interface
 * @export
 * @class PurchaseApi
 * @extends {BaseAPI}
 */
var PurchaseApi = /** @class */ (function (_super) {
    __extends(PurchaseApi, _super);
    function PurchaseApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary ask a refund for a purchase
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseApi
     */
    PurchaseApi.prototype.askRefundForPurchaseById = function (id, options) {
        var _this = this;
        return (0, exports.PurchaseApiFp)(this.configuration).askRefundForPurchaseById(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary get count of purchases
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseApi
     */
    PurchaseApi.prototype.countPurchases = function (options) {
        var _this = this;
        return (0, exports.PurchaseApiFp)(this.configuration).countPurchases(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary creates new purchase
     * @param {PurchaseInput} [purchaseInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseApi
     */
    PurchaseApi.prototype.createPurchase = function (purchaseInput, options) {
        var _this = this;
        return (0, exports.PurchaseApiFp)(this.configuration).createPurchase(purchaseInput, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary edit purchased ticket
     * @param {string} id
     * @param {InputEditPurchasedTicket} [inputEditPurchasedTicket]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseApi
     */
    PurchaseApi.prototype.editPurchasedTicket = function (id, inputEditPurchasedTicket, options) {
        var _this = this;
        return (0, exports.PurchaseApiFp)(this.configuration).editPurchasedTicket(id, inputEditPurchasedTicket, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary get all purchases either current and past
     * @param {boolean} [paginate]
     * @param {number} [size]
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseApi
     */
    PurchaseApi.prototype.getAllPurchases = function (paginate, size, page, options) {
        var _this = this;
        return (0, exports.PurchaseApiFp)(this.configuration).getAllPurchases(paginate, size, page, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary get all current purchases
     * @param {boolean} [paginate]
     * @param {number} [size]
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseApi
     */
    PurchaseApi.prototype.getCurrentPurchases = function (paginate, size, page, options) {
        var _this = this;
        return (0, exports.PurchaseApiFp)(this.configuration).getCurrentPurchases(paginate, size, page, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary get all past purchases
     * @param {boolean} [paginate]
     * @param {number} [size]
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseApi
     */
    PurchaseApi.prototype.getPastPurchases = function (paginate, size, page, options) {
        var _this = this;
        return (0, exports.PurchaseApiFp)(this.configuration).getPastPurchases(paginate, size, page, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary get purchase by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseApi
     */
    PurchaseApi.prototype.getPurchaseById = function (id, options) {
        var _this = this;
        return (0, exports.PurchaseApiFp)(this.configuration).getPurchaseById(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary get purchase tickets for an event
     * @param {string} eventId
     * @param {string} [date]
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [qry]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseApi
     */
    PurchaseApi.prototype.getPurchaseTicketsByEventIdForOwner = function (eventId, date, page, size, qry, options) {
        var _this = this;
        return (0, exports.PurchaseApiFp)(this.configuration).getPurchaseTicketsByEventIdForOwner(eventId, date, page, size, qry, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get purchases by event
     * @param {string} [page]
     * @param {string} [perPage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseApi
     */
    PurchaseApi.prototype.getPurchasesByEvent = function (page, perPage, options) {
        var _this = this;
        return (0, exports.PurchaseApiFp)(this.configuration).getPurchasesByEvent(page, perPage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary get all purchases by event and date
     * @param {number} eventId
     * @param {string} [date]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseApi
     */
    PurchaseApi.prototype.getPurchasesByEventAndDate = function (eventId, date, options) {
        var _this = this;
        return (0, exports.PurchaseApiFp)(this.configuration).getPurchasesByEventAndDate(eventId, date, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary get order for an event
     * @param {string} eventId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseApi
     */
    PurchaseApi.prototype.getPurchasesForEventId = function (eventId, options) {
        var _this = this;
        return (0, exports.PurchaseApiFp)(this.configuration).getPurchasesForEventId(eventId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Revoke share purchase ticket by id
     * @param {string} purchaseTicketId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseApi
     */
    PurchaseApi.prototype.revokeSharePurchaseTicketById = function (purchaseTicketId, options) {
        var _this = this;
        return (0, exports.PurchaseApiFp)(this.configuration).revokeSharePurchaseTicketById(purchaseTicketId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Share purchase ticket by id
     * @param {string} purchaseTicketId
     * @param {InputSharePurchaseTicketById} [inputSharePurchaseTicketById]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseApi
     */
    PurchaseApi.prototype.sharePurchaseTicketById = function (purchaseTicketId, inputSharePurchaseTicketById, options) {
        var _this = this;
        return (0, exports.PurchaseApiFp)(this.configuration).sharePurchaseTicketById(purchaseTicketId, inputSharePurchaseTicketById, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary validates a ticket
     * @param {string} purchaseTicketId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseApi
     */
    PurchaseApi.prototype.validatePurchaseTicketById = function (purchaseTicketId, options) {
        var _this = this;
        return (0, exports.PurchaseApiFp)(this.configuration).validatePurchaseTicketById(purchaseTicketId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return PurchaseApi;
}(base_1.BaseAPI));
exports.PurchaseApi = PurchaseApi;
/**
 * RefundsApi - axios parameter creator
 * @export
 */
var RefundsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary get refund byId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geRefundById: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('geRefundById', 'id', id);
                            localVarPath = "/host/refunds/{id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary get all purchases either current and past
         * @param {boolean} [paginate]
         * @param {number} [size]
         * @param {number} [page]
         * @param {number} [eventId]
         * @param {'all' | 'askedRefund' | 'refunded'} [filter]
         * @param {string} [monthDate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRefunds: function (paginate, size, page, eventId, filter, monthDate, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/host/refunds/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            if (paginate !== undefined) {
                                localVarQueryParameter['paginate'] = paginate;
                            }
                            if (size !== undefined) {
                                localVarQueryParameter['size'] = size;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (eventId !== undefined) {
                                localVarQueryParameter['eventId'] = eventId;
                            }
                            if (filter !== undefined) {
                                localVarQueryParameter['filter'] = filter;
                            }
                            if (monthDate !== undefined) {
                                localVarQueryParameter['monthDate'] = (monthDate instanceof Date) ?
                                    monthDate.toISOString().substr(0, 10) :
                                    monthDate;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.RefundsApiAxiosParamCreator = RefundsApiAxiosParamCreator;
/**
 * RefundsApi - functional programming interface
 * @export
 */
var RefundsApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.RefundsApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary get refund byId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geRefundById: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.geRefundById(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary get all purchases either current and past
         * @param {boolean} [paginate]
         * @param {number} [size]
         * @param {number} [page]
         * @param {number} [eventId]
         * @param {'all' | 'askedRefund' | 'refunded'} [filter]
         * @param {string} [monthDate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRefunds: function (paginate, size, page, eventId, filter, monthDate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getAllRefunds(paginate, size, page, eventId, filter, monthDate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.RefundsApiFp = RefundsApiFp;
/**
 * RefundsApi - factory interface
 * @export
 */
var RefundsApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.RefundsApiFp)(configuration);
    return {
        /**
         *
         * @summary get refund byId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geRefundById: function (id, options) {
            return localVarFp.geRefundById(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary get all purchases either current and past
         * @param {boolean} [paginate]
         * @param {number} [size]
         * @param {number} [page]
         * @param {number} [eventId]
         * @param {'all' | 'askedRefund' | 'refunded'} [filter]
         * @param {string} [monthDate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRefunds: function (paginate, size, page, eventId, filter, monthDate, options) {
            return localVarFp.getAllRefunds(paginate, size, page, eventId, filter, monthDate, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.RefundsApiFactory = RefundsApiFactory;
/**
 * RefundsApi - object-oriented interface
 * @export
 * @class RefundsApi
 * @extends {BaseAPI}
 */
var RefundsApi = /** @class */ (function (_super) {
    __extends(RefundsApi, _super);
    function RefundsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary get refund byId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RefundsApi
     */
    RefundsApi.prototype.geRefundById = function (id, options) {
        var _this = this;
        return (0, exports.RefundsApiFp)(this.configuration).geRefundById(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary get all purchases either current and past
     * @param {boolean} [paginate]
     * @param {number} [size]
     * @param {number} [page]
     * @param {number} [eventId]
     * @param {'all' | 'askedRefund' | 'refunded'} [filter]
     * @param {string} [monthDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RefundsApi
     */
    RefundsApi.prototype.getAllRefunds = function (paginate, size, page, eventId, filter, monthDate, options) {
        var _this = this;
        return (0, exports.RefundsApiFp)(this.configuration).getAllRefunds(paginate, size, page, eventId, filter, monthDate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return RefundsApi;
}(base_1.BaseAPI));
exports.RefundsApi = RefundsApi;
/**
 * SeatConfiguratorApi - axios parameter creator
 * @export
 */
var SeatConfiguratorApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Can edit seat configurator map
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canEditSeatConfiguratorMap: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('canEditSeatConfiguratorMap', 'id', id);
                            localVarPath = "/seat-configurator/{id}/can-edit"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Create seat configurator map
         * @param {SeatConfiguratorMapInput} [seatConfiguratorMapInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSeatConfiguratorMap: function (seatConfiguratorMapInput, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/seat-configurator/create";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(seatConfiguratorMapInput, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Delete seat configurator map
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSeatConfiguratorMap: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('deleteSeatConfiguratorMap', 'id', id);
                            localVarPath = "/seat-configurator/{id}/delete"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Duplicate seat configurator map
         * @param {string} id
         * @param {InputDuplicateSeatConfiguratorMap} [inputDuplicateSeatConfiguratorMap]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateSeatConfiguratorMap: function (id, inputDuplicateSeatConfiguratorMap, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('duplicateSeatConfiguratorMap', 'id', id);
                            localVarPath = "/seat-configurator/{id}/duplicate"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputDuplicateSeatConfiguratorMap, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Edit seat configurator map
         * @param {string} id
         * @param {SeatConfiguratorMapInput} [seatConfiguratorMapInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editSeatConfiguratorMap: function (id, seatConfiguratorMapInput, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('editSeatConfiguratorMap', 'id', id);
                            localVarPath = "/seat-configurator/{id}/edit"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(seatConfiguratorMapInput, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get single map by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleMapById: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('getSingleMapById', 'id', id);
                            localVarPath = "/seat-configurator/{id}/"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary List maps
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMaps: function (filter, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/seat-configurator/list";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            if (filter !== undefined) {
                                localVarQueryParameter['filter'] = filter;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.SeatConfiguratorApiAxiosParamCreator = SeatConfiguratorApiAxiosParamCreator;
/**
 * SeatConfiguratorApi - functional programming interface
 * @export
 */
var SeatConfiguratorApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.SeatConfiguratorApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Can edit seat configurator map
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canEditSeatConfiguratorMap: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.canEditSeatConfiguratorMap(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Create seat configurator map
         * @param {SeatConfiguratorMapInput} [seatConfiguratorMapInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSeatConfiguratorMap: function (seatConfiguratorMapInput, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createSeatConfiguratorMap(seatConfiguratorMapInput, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Delete seat configurator map
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSeatConfiguratorMap: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteSeatConfiguratorMap(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Duplicate seat configurator map
         * @param {string} id
         * @param {InputDuplicateSeatConfiguratorMap} [inputDuplicateSeatConfiguratorMap]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateSeatConfiguratorMap: function (id, inputDuplicateSeatConfiguratorMap, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.duplicateSeatConfiguratorMap(id, inputDuplicateSeatConfiguratorMap, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Edit seat configurator map
         * @param {string} id
         * @param {SeatConfiguratorMapInput} [seatConfiguratorMapInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editSeatConfiguratorMap: function (id, seatConfiguratorMapInput, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.editSeatConfiguratorMap(id, seatConfiguratorMapInput, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get single map by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleMapById: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getSingleMapById(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary List maps
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMaps: function (filter, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listMaps(filter, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.SeatConfiguratorApiFp = SeatConfiguratorApiFp;
/**
 * SeatConfiguratorApi - factory interface
 * @export
 */
var SeatConfiguratorApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.SeatConfiguratorApiFp)(configuration);
    return {
        /**
         *
         * @summary Can edit seat configurator map
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canEditSeatConfiguratorMap: function (id, options) {
            return localVarFp.canEditSeatConfiguratorMap(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Create seat configurator map
         * @param {SeatConfiguratorMapInput} [seatConfiguratorMapInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSeatConfiguratorMap: function (seatConfiguratorMapInput, options) {
            return localVarFp.createSeatConfiguratorMap(seatConfiguratorMapInput, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Delete seat configurator map
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSeatConfiguratorMap: function (id, options) {
            return localVarFp.deleteSeatConfiguratorMap(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Duplicate seat configurator map
         * @param {string} id
         * @param {InputDuplicateSeatConfiguratorMap} [inputDuplicateSeatConfiguratorMap]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateSeatConfiguratorMap: function (id, inputDuplicateSeatConfiguratorMap, options) {
            return localVarFp.duplicateSeatConfiguratorMap(id, inputDuplicateSeatConfiguratorMap, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Edit seat configurator map
         * @param {string} id
         * @param {SeatConfiguratorMapInput} [seatConfiguratorMapInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editSeatConfiguratorMap: function (id, seatConfiguratorMapInput, options) {
            return localVarFp.editSeatConfiguratorMap(id, seatConfiguratorMapInput, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get single map by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleMapById: function (id, options) {
            return localVarFp.getSingleMapById(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary List maps
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMaps: function (filter, options) {
            return localVarFp.listMaps(filter, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.SeatConfiguratorApiFactory = SeatConfiguratorApiFactory;
/**
 * SeatConfiguratorApi - object-oriented interface
 * @export
 * @class SeatConfiguratorApi
 * @extends {BaseAPI}
 */
var SeatConfiguratorApi = /** @class */ (function (_super) {
    __extends(SeatConfiguratorApi, _super);
    function SeatConfiguratorApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Can edit seat configurator map
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeatConfiguratorApi
     */
    SeatConfiguratorApi.prototype.canEditSeatConfiguratorMap = function (id, options) {
        var _this = this;
        return (0, exports.SeatConfiguratorApiFp)(this.configuration).canEditSeatConfiguratorMap(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Create seat configurator map
     * @param {SeatConfiguratorMapInput} [seatConfiguratorMapInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeatConfiguratorApi
     */
    SeatConfiguratorApi.prototype.createSeatConfiguratorMap = function (seatConfiguratorMapInput, options) {
        var _this = this;
        return (0, exports.SeatConfiguratorApiFp)(this.configuration).createSeatConfiguratorMap(seatConfiguratorMapInput, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Delete seat configurator map
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeatConfiguratorApi
     */
    SeatConfiguratorApi.prototype.deleteSeatConfiguratorMap = function (id, options) {
        var _this = this;
        return (0, exports.SeatConfiguratorApiFp)(this.configuration).deleteSeatConfiguratorMap(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Duplicate seat configurator map
     * @param {string} id
     * @param {InputDuplicateSeatConfiguratorMap} [inputDuplicateSeatConfiguratorMap]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeatConfiguratorApi
     */
    SeatConfiguratorApi.prototype.duplicateSeatConfiguratorMap = function (id, inputDuplicateSeatConfiguratorMap, options) {
        var _this = this;
        return (0, exports.SeatConfiguratorApiFp)(this.configuration).duplicateSeatConfiguratorMap(id, inputDuplicateSeatConfiguratorMap, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Edit seat configurator map
     * @param {string} id
     * @param {SeatConfiguratorMapInput} [seatConfiguratorMapInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeatConfiguratorApi
     */
    SeatConfiguratorApi.prototype.editSeatConfiguratorMap = function (id, seatConfiguratorMapInput, options) {
        var _this = this;
        return (0, exports.SeatConfiguratorApiFp)(this.configuration).editSeatConfiguratorMap(id, seatConfiguratorMapInput, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get single map by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeatConfiguratorApi
     */
    SeatConfiguratorApi.prototype.getSingleMapById = function (id, options) {
        var _this = this;
        return (0, exports.SeatConfiguratorApiFp)(this.configuration).getSingleMapById(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary List maps
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeatConfiguratorApi
     */
    SeatConfiguratorApi.prototype.listMaps = function (filter, options) {
        var _this = this;
        return (0, exports.SeatConfiguratorApiFp)(this.configuration).listMaps(filter, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return SeatConfiguratorApi;
}(base_1.BaseAPI));
exports.SeatConfiguratorApi = SeatConfiguratorApi;
/**
 * TicketApi - axios parameter creator
 * @export
 */
var TicketApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Create ticket
         * @param {TicketInput} [ticketInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTicket: function (ticketInput, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/ticket/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(ticketInput, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary deletes a ticket
         * @param {string} ticketId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTicketById: function (ticketId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'ticketId' is not null or undefined
                            (0, common_1.assertParamExists)('deleteTicketById', 'ticketId', ticketId);
                            localVarPath = "/ticket/{ticketId}/"
                                .replace("{".concat("ticketId", "}"), encodeURIComponent(String(ticketId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary edit a ticket
         * @param {InputEditTicket} [inputEditTicket]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editTicket: function (inputEditTicket, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/ticket/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputEditTicket, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get public info for ticket by id
         * @param {string} ticketId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicInfoForTicketById: function (ticketId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'ticketId' is not null or undefined
                            (0, common_1.assertParamExists)('getPublicInfoForTicketById', 'ticketId', ticketId);
                            localVarPath = "/ticket/{ticketId}/public"
                                .replace("{".concat("ticketId", "}"), encodeURIComponent(String(ticketId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary get single ticket by id
         * @param {string} ticketId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketById: function (ticketId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'ticketId' is not null or undefined
                            (0, common_1.assertParamExists)('getTicketById', 'ticketId', ticketId);
                            localVarPath = "/ticket/{ticketId}/"
                                .replace("{".concat("ticketId", "}"), encodeURIComponent(String(ticketId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary List my tickets
         * @param {string} [entrance]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMyTickets: function (entrance, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/ticket/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            if (entrance !== undefined) {
                                localVarQueryParameter['entrance'] = entrance;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.TicketApiAxiosParamCreator = TicketApiAxiosParamCreator;
/**
 * TicketApi - functional programming interface
 * @export
 */
var TicketApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.TicketApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Create ticket
         * @param {TicketInput} [ticketInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTicket: function (ticketInput, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createTicket(ticketInput, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary deletes a ticket
         * @param {string} ticketId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTicketById: function (ticketId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteTicketById(ticketId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary edit a ticket
         * @param {InputEditTicket} [inputEditTicket]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editTicket: function (inputEditTicket, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.editTicket(inputEditTicket, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get public info for ticket by id
         * @param {string} ticketId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicInfoForTicketById: function (ticketId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getPublicInfoForTicketById(ticketId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary get single ticket by id
         * @param {string} ticketId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketById: function (ticketId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getTicketById(ticketId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary List my tickets
         * @param {string} [entrance]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMyTickets: function (entrance, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listMyTickets(entrance, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.TicketApiFp = TicketApiFp;
/**
 * TicketApi - factory interface
 * @export
 */
var TicketApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.TicketApiFp)(configuration);
    return {
        /**
         *
         * @summary Create ticket
         * @param {TicketInput} [ticketInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTicket: function (ticketInput, options) {
            return localVarFp.createTicket(ticketInput, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary deletes a ticket
         * @param {string} ticketId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTicketById: function (ticketId, options) {
            return localVarFp.deleteTicketById(ticketId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary edit a ticket
         * @param {InputEditTicket} [inputEditTicket]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editTicket: function (inputEditTicket, options) {
            return localVarFp.editTicket(inputEditTicket, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get public info for ticket by id
         * @param {string} ticketId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicInfoForTicketById: function (ticketId, options) {
            return localVarFp.getPublicInfoForTicketById(ticketId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary get single ticket by id
         * @param {string} ticketId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketById: function (ticketId, options) {
            return localVarFp.getTicketById(ticketId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary List my tickets
         * @param {string} [entrance]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMyTickets: function (entrance, options) {
            return localVarFp.listMyTickets(entrance, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.TicketApiFactory = TicketApiFactory;
/**
 * TicketApi - object-oriented interface
 * @export
 * @class TicketApi
 * @extends {BaseAPI}
 */
var TicketApi = /** @class */ (function (_super) {
    __extends(TicketApi, _super);
    function TicketApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Create ticket
     * @param {TicketInput} [ticketInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApi
     */
    TicketApi.prototype.createTicket = function (ticketInput, options) {
        var _this = this;
        return (0, exports.TicketApiFp)(this.configuration).createTicket(ticketInput, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary deletes a ticket
     * @param {string} ticketId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApi
     */
    TicketApi.prototype.deleteTicketById = function (ticketId, options) {
        var _this = this;
        return (0, exports.TicketApiFp)(this.configuration).deleteTicketById(ticketId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary edit a ticket
     * @param {InputEditTicket} [inputEditTicket]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApi
     */
    TicketApi.prototype.editTicket = function (inputEditTicket, options) {
        var _this = this;
        return (0, exports.TicketApiFp)(this.configuration).editTicket(inputEditTicket, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get public info for ticket by id
     * @param {string} ticketId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApi
     */
    TicketApi.prototype.getPublicInfoForTicketById = function (ticketId, options) {
        var _this = this;
        return (0, exports.TicketApiFp)(this.configuration).getPublicInfoForTicketById(ticketId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary get single ticket by id
     * @param {string} ticketId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApi
     */
    TicketApi.prototype.getTicketById = function (ticketId, options) {
        var _this = this;
        return (0, exports.TicketApiFp)(this.configuration).getTicketById(ticketId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary List my tickets
     * @param {string} [entrance]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApi
     */
    TicketApi.prototype.listMyTickets = function (entrance, options) {
        var _this = this;
        return (0, exports.TicketApiFp)(this.configuration).listMyTickets(entrance, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return TicketApi;
}(base_1.BaseAPI));
exports.TicketApi = TicketApi;
/**
 * TicketEventApi - axios parameter creator
 * @export
 */
var TicketEventApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary creates new ticket event
         * @param {TicketEventCreateInput} [ticketEventCreateInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTicketEvent: function (ticketEventCreateInput, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/ticket-event/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(ticketEventCreateInput, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary deletes a ticket event
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTicketEventById: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('deleteTicketEventById', 'id', id);
                            localVarPath = "/ticket-event/{id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary edit a ticket event
         * @param {TicketEventEditInput} [ticketEventEditInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editTicketEvent: function (ticketEventEditInput, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/ticket-event/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(ticketEventEditInput, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get public ticket events by event id
         * @param {string} eventId
         * @param {string} [date]
         * @param {boolean} [availabilityForHost]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicTicketEventsByEventId: function (eventId, date, availabilityForHost, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'eventId' is not null or undefined
                            (0, common_1.assertParamExists)('getPublicTicketEventsByEventId', 'eventId', eventId);
                            localVarPath = "/ticket-event/event/{eventId}/"
                                .replace("{".concat("eventId", "}"), encodeURIComponent(String(eventId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            if (date !== undefined) {
                                localVarQueryParameter['date'] = date;
                            }
                            if (availabilityForHost !== undefined) {
                                localVarQueryParameter['availability-for-host'] = availabilityForHost;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get reserved places from reservation id
         * @param {string} reservationId
         * @param {string} eventId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReservedPlacesFromReservationId: function (reservationId, eventId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'reservationId' is not null or undefined
                            (0, common_1.assertParamExists)('getReservedPlacesFromReservationId', 'reservationId', reservationId);
                            // verify required parameter 'eventId' is not null or undefined
                            (0, common_1.assertParamExists)('getReservedPlacesFromReservationId', 'eventId', eventId);
                            localVarPath = "/ticket-event/event/{eventId}/reserved-places/"
                                .replace("{".concat("eventId", "}"), encodeURIComponent(String(eventId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            if (reservationId !== undefined) {
                                localVarQueryParameter['reservationId'] = reservationId;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get seat ticket for event id
         * @param {string} eventId
         * @param {string} [date]
         * @param {string} [reservationId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeatTicketForEventId: function (eventId, date, reservationId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'eventId' is not null or undefined
                            (0, common_1.assertParamExists)('getSeatTicketForEventId', 'eventId', eventId);
                            localVarPath = "/ticket-event/event/{eventId}/seat-tickets"
                                .replace("{".concat("eventId", "}"), encodeURIComponent(String(eventId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            if (date !== undefined) {
                                localVarQueryParameter['date'] = date;
                            }
                            if (reservationId !== undefined) {
                                localVarQueryParameter['reservationId'] = reservationId;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get seat ticket for event id for host
         * @param {string} eventId
         * @param {string} [date]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeatTicketForEventIdForHost: function (eventId, date, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'eventId' is not null or undefined
                            (0, common_1.assertParamExists)('getSeatTicketForEventIdForHost', 'eventId', eventId);
                            localVarPath = "/ticket-event/event/{eventId}/seat-tickets-host"
                                .replace("{".concat("eventId", "}"), encodeURIComponent(String(eventId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            if (date !== undefined) {
                                localVarQueryParameter['date'] = date;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get starting entrance price
         * @param {string} eventId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStartingEntrancePrice: function (eventId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'eventId' is not null or undefined
                            (0, common_1.assertParamExists)('getStartingEntrancePrice', 'eventId', eventId);
                            localVarPath = "/ticket-event/event/{eventId}/starting-entrance-price"
                                .replace("{".concat("eventId", "}"), encodeURIComponent(String(eventId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary get single ticket event by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketEventById: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('getTicketEventById', 'id', id);
                            localVarPath = "/ticket-event/{id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get ticket events by event id for collaborators
         * @param {string} eventId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketEventsByEventIdForCollaborators: function (eventId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'eventId' is not null or undefined
                            (0, common_1.assertParamExists)('getTicketEventsByEventIdForCollaborators', 'eventId', eventId);
                            localVarPath = "/ticket-event/event/{eventId}/host/in-event-tickets"
                                .replace("{".concat("eventId", "}"), encodeURIComponent(String(eventId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Reserve places for event id
         * @param {string} eventId
         * @param {InputReservePlacesForEventId} [inputReservePlacesForEventId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reservePlacesForEventId: function (eventId, inputReservePlacesForEventId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'eventId' is not null or undefined
                            (0, common_1.assertParamExists)('reservePlacesForEventId', 'eventId', eventId);
                            localVarPath = "/ticket-event/event/{eventId}/reserved-places/"
                                .replace("{".concat("eventId", "}"), encodeURIComponent(String(eventId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputReservePlacesForEventId, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.TicketEventApiAxiosParamCreator = TicketEventApiAxiosParamCreator;
/**
 * TicketEventApi - functional programming interface
 * @export
 */
var TicketEventApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.TicketEventApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary creates new ticket event
         * @param {TicketEventCreateInput} [ticketEventCreateInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTicketEvent: function (ticketEventCreateInput, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createTicketEvent(ticketEventCreateInput, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary deletes a ticket event
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTicketEventById: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteTicketEventById(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary edit a ticket event
         * @param {TicketEventEditInput} [ticketEventEditInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editTicketEvent: function (ticketEventEditInput, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.editTicketEvent(ticketEventEditInput, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get public ticket events by event id
         * @param {string} eventId
         * @param {string} [date]
         * @param {boolean} [availabilityForHost]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicTicketEventsByEventId: function (eventId, date, availabilityForHost, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getPublicTicketEventsByEventId(eventId, date, availabilityForHost, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get reserved places from reservation id
         * @param {string} reservationId
         * @param {string} eventId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReservedPlacesFromReservationId: function (reservationId, eventId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getReservedPlacesFromReservationId(reservationId, eventId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get seat ticket for event id
         * @param {string} eventId
         * @param {string} [date]
         * @param {string} [reservationId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeatTicketForEventId: function (eventId, date, reservationId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getSeatTicketForEventId(eventId, date, reservationId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get seat ticket for event id for host
         * @param {string} eventId
         * @param {string} [date]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeatTicketForEventIdForHost: function (eventId, date, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getSeatTicketForEventIdForHost(eventId, date, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get starting entrance price
         * @param {string} eventId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStartingEntrancePrice: function (eventId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getStartingEntrancePrice(eventId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary get single ticket event by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketEventById: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getTicketEventById(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get ticket events by event id for collaborators
         * @param {string} eventId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketEventsByEventIdForCollaborators: function (eventId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getTicketEventsByEventIdForCollaborators(eventId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Reserve places for event id
         * @param {string} eventId
         * @param {InputReservePlacesForEventId} [inputReservePlacesForEventId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reservePlacesForEventId: function (eventId, inputReservePlacesForEventId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.reservePlacesForEventId(eventId, inputReservePlacesForEventId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.TicketEventApiFp = TicketEventApiFp;
/**
 * TicketEventApi - factory interface
 * @export
 */
var TicketEventApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.TicketEventApiFp)(configuration);
    return {
        /**
         *
         * @summary creates new ticket event
         * @param {TicketEventCreateInput} [ticketEventCreateInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTicketEvent: function (ticketEventCreateInput, options) {
            return localVarFp.createTicketEvent(ticketEventCreateInput, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary deletes a ticket event
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTicketEventById: function (id, options) {
            return localVarFp.deleteTicketEventById(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary edit a ticket event
         * @param {TicketEventEditInput} [ticketEventEditInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editTicketEvent: function (ticketEventEditInput, options) {
            return localVarFp.editTicketEvent(ticketEventEditInput, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get public ticket events by event id
         * @param {string} eventId
         * @param {string} [date]
         * @param {boolean} [availabilityForHost]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicTicketEventsByEventId: function (eventId, date, availabilityForHost, options) {
            return localVarFp.getPublicTicketEventsByEventId(eventId, date, availabilityForHost, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get reserved places from reservation id
         * @param {string} reservationId
         * @param {string} eventId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReservedPlacesFromReservationId: function (reservationId, eventId, options) {
            return localVarFp.getReservedPlacesFromReservationId(reservationId, eventId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get seat ticket for event id
         * @param {string} eventId
         * @param {string} [date]
         * @param {string} [reservationId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeatTicketForEventId: function (eventId, date, reservationId, options) {
            return localVarFp.getSeatTicketForEventId(eventId, date, reservationId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get seat ticket for event id for host
         * @param {string} eventId
         * @param {string} [date]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeatTicketForEventIdForHost: function (eventId, date, options) {
            return localVarFp.getSeatTicketForEventIdForHost(eventId, date, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get starting entrance price
         * @param {string} eventId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStartingEntrancePrice: function (eventId, options) {
            return localVarFp.getStartingEntrancePrice(eventId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary get single ticket event by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketEventById: function (id, options) {
            return localVarFp.getTicketEventById(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get ticket events by event id for collaborators
         * @param {string} eventId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketEventsByEventIdForCollaborators: function (eventId, options) {
            return localVarFp.getTicketEventsByEventIdForCollaborators(eventId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Reserve places for event id
         * @param {string} eventId
         * @param {InputReservePlacesForEventId} [inputReservePlacesForEventId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reservePlacesForEventId: function (eventId, inputReservePlacesForEventId, options) {
            return localVarFp.reservePlacesForEventId(eventId, inputReservePlacesForEventId, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.TicketEventApiFactory = TicketEventApiFactory;
/**
 * TicketEventApi - object-oriented interface
 * @export
 * @class TicketEventApi
 * @extends {BaseAPI}
 */
var TicketEventApi = /** @class */ (function (_super) {
    __extends(TicketEventApi, _super);
    function TicketEventApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary creates new ticket event
     * @param {TicketEventCreateInput} [ticketEventCreateInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketEventApi
     */
    TicketEventApi.prototype.createTicketEvent = function (ticketEventCreateInput, options) {
        var _this = this;
        return (0, exports.TicketEventApiFp)(this.configuration).createTicketEvent(ticketEventCreateInput, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary deletes a ticket event
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketEventApi
     */
    TicketEventApi.prototype.deleteTicketEventById = function (id, options) {
        var _this = this;
        return (0, exports.TicketEventApiFp)(this.configuration).deleteTicketEventById(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary edit a ticket event
     * @param {TicketEventEditInput} [ticketEventEditInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketEventApi
     */
    TicketEventApi.prototype.editTicketEvent = function (ticketEventEditInput, options) {
        var _this = this;
        return (0, exports.TicketEventApiFp)(this.configuration).editTicketEvent(ticketEventEditInput, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get public ticket events by event id
     * @param {string} eventId
     * @param {string} [date]
     * @param {boolean} [availabilityForHost]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketEventApi
     */
    TicketEventApi.prototype.getPublicTicketEventsByEventId = function (eventId, date, availabilityForHost, options) {
        var _this = this;
        return (0, exports.TicketEventApiFp)(this.configuration).getPublicTicketEventsByEventId(eventId, date, availabilityForHost, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get reserved places from reservation id
     * @param {string} reservationId
     * @param {string} eventId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketEventApi
     */
    TicketEventApi.prototype.getReservedPlacesFromReservationId = function (reservationId, eventId, options) {
        var _this = this;
        return (0, exports.TicketEventApiFp)(this.configuration).getReservedPlacesFromReservationId(reservationId, eventId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get seat ticket for event id
     * @param {string} eventId
     * @param {string} [date]
     * @param {string} [reservationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketEventApi
     */
    TicketEventApi.prototype.getSeatTicketForEventId = function (eventId, date, reservationId, options) {
        var _this = this;
        return (0, exports.TicketEventApiFp)(this.configuration).getSeatTicketForEventId(eventId, date, reservationId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get seat ticket for event id for host
     * @param {string} eventId
     * @param {string} [date]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketEventApi
     */
    TicketEventApi.prototype.getSeatTicketForEventIdForHost = function (eventId, date, options) {
        var _this = this;
        return (0, exports.TicketEventApiFp)(this.configuration).getSeatTicketForEventIdForHost(eventId, date, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get starting entrance price
     * @param {string} eventId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketEventApi
     */
    TicketEventApi.prototype.getStartingEntrancePrice = function (eventId, options) {
        var _this = this;
        return (0, exports.TicketEventApiFp)(this.configuration).getStartingEntrancePrice(eventId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary get single ticket event by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketEventApi
     */
    TicketEventApi.prototype.getTicketEventById = function (id, options) {
        var _this = this;
        return (0, exports.TicketEventApiFp)(this.configuration).getTicketEventById(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get ticket events by event id for collaborators
     * @param {string} eventId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketEventApi
     */
    TicketEventApi.prototype.getTicketEventsByEventIdForCollaborators = function (eventId, options) {
        var _this = this;
        return (0, exports.TicketEventApiFp)(this.configuration).getTicketEventsByEventIdForCollaborators(eventId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Reserve places for event id
     * @param {string} eventId
     * @param {InputReservePlacesForEventId} [inputReservePlacesForEventId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketEventApi
     */
    TicketEventApi.prototype.reservePlacesForEventId = function (eventId, inputReservePlacesForEventId, options) {
        var _this = this;
        return (0, exports.TicketEventApiFp)(this.configuration).reservePlacesForEventId(eventId, inputReservePlacesForEventId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return TicketEventApi;
}(base_1.BaseAPI));
exports.TicketEventApi = TicketEventApi;
/**
 * TicketEventDashboardApi - axios parameter creator
 * @export
 */
var TicketEventDashboardApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Download ticket reports for event dashboard
         * @param {string} eventId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {InputDownloadTicketReportsForEventDashboard} [inputDownloadTicketReportsForEventDashboard]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTicketReportsForEventDashboard: function (eventId, filter, inputDownloadTicketReportsForEventDashboard, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'eventId' is not null or undefined
                            (0, common_1.assertParamExists)('downloadTicketReportsForEventDashboard', 'eventId', eventId);
                            localVarPath = "/ticket-event/event/{eventId}/dashboard/report"
                                .replace("{".concat("eventId", "}"), encodeURIComponent(String(eventId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            if (filter !== undefined) {
                                localVarQueryParameter['filter'] = filter;
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputDownloadTicketReportsForEventDashboard, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get participants for event dashboard
         * @param {string} eventId
         * @param {string} [date]
         * @param {string} [download]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParticipantsForEventDashboard: function (eventId, date, download, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'eventId' is not null or undefined
                            (0, common_1.assertParamExists)('getParticipantsForEventDashboard', 'eventId', eventId);
                            localVarPath = "/ticket-event/event/{eventId}/dashboard/participants"
                                .replace("{".concat("eventId", "}"), encodeURIComponent(String(eventId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            if (date !== undefined) {
                                localVarQueryParameter['date'] = date;
                            }
                            if (download !== undefined) {
                                localVarQueryParameter['download'] = download;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get single participant for event dashboard
         * @param {string} eventId
         * @param {string} email
         * @param {string} [date]
         * @param {string} [ownerId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleParticipantForEventDashboard: function (eventId, email, date, ownerId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'eventId' is not null or undefined
                            (0, common_1.assertParamExists)('getSingleParticipantForEventDashboard', 'eventId', eventId);
                            // verify required parameter 'email' is not null or undefined
                            (0, common_1.assertParamExists)('getSingleParticipantForEventDashboard', 'email', email);
                            localVarPath = "/ticket-event/event/{eventId}/dashboard/participant/{email}"
                                .replace("{".concat("eventId", "}"), encodeURIComponent(String(eventId)))
                                .replace("{".concat("email", "}"), encodeURIComponent(String(email)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            if (date !== undefined) {
                                localVarQueryParameter['date'] = date;
                            }
                            if (ownerId !== undefined) {
                                localVarQueryParameter['ownerId'] = ownerId;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get single ticket event dashboard
         * @param {string} eventId
         * @param {string} ticketId
         * @param {string} [date]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleTicketEventDashboard: function (eventId, ticketId, date, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'eventId' is not null or undefined
                            (0, common_1.assertParamExists)('getSingleTicketEventDashboard', 'eventId', eventId);
                            // verify required parameter 'ticketId' is not null or undefined
                            (0, common_1.assertParamExists)('getSingleTicketEventDashboard', 'ticketId', ticketId);
                            localVarPath = "/ticket-event/event/{eventId}/dashboard/ticket/{ticketId}"
                                .replace("{".concat("eventId", "}"), encodeURIComponent(String(eventId)))
                                .replace("{".concat("ticketId", "}"), encodeURIComponent(String(ticketId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            if (date !== undefined) {
                                localVarQueryParameter['date'] = date;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get ticket events dashboard
         * @param {string} eventId
         * @param {string} [date]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketEventsDashboard: function (eventId, date, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'eventId' is not null or undefined
                            (0, common_1.assertParamExists)('getTicketEventsDashboard', 'eventId', eventId);
                            localVarPath = "/ticket-event/event/{eventId}/dashboard/"
                                .replace("{".concat("eventId", "}"), encodeURIComponent(String(eventId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            if (date !== undefined) {
                                localVarQueryParameter['date'] = date;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get ticket reports for event dashboard
         * @param {string} eventId
         * @param {string} [date]
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {'all' | 'mine'} [filterTicket]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketReportsForEventDashboard: function (eventId, date, filter, filterTicket, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'eventId' is not null or undefined
                            (0, common_1.assertParamExists)('getTicketReportsForEventDashboard', 'eventId', eventId);
                            localVarPath = "/ticket-event/event/{eventId}/dashboard/report"
                                .replace("{".concat("eventId", "}"), encodeURIComponent(String(eventId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            if (date !== undefined) {
                                localVarQueryParameter['date'] = date;
                            }
                            if (filter !== undefined) {
                                localVarQueryParameter['filter'] = filter;
                            }
                            if (filterTicket !== undefined) {
                                localVarQueryParameter['filterTicket'] = filterTicket;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.TicketEventDashboardApiAxiosParamCreator = TicketEventDashboardApiAxiosParamCreator;
/**
 * TicketEventDashboardApi - functional programming interface
 * @export
 */
var TicketEventDashboardApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.TicketEventDashboardApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Download ticket reports for event dashboard
         * @param {string} eventId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {InputDownloadTicketReportsForEventDashboard} [inputDownloadTicketReportsForEventDashboard]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTicketReportsForEventDashboard: function (eventId, filter, inputDownloadTicketReportsForEventDashboard, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.downloadTicketReportsForEventDashboard(eventId, filter, inputDownloadTicketReportsForEventDashboard, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get participants for event dashboard
         * @param {string} eventId
         * @param {string} [date]
         * @param {string} [download]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParticipantsForEventDashboard: function (eventId, date, download, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getParticipantsForEventDashboard(eventId, date, download, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get single participant for event dashboard
         * @param {string} eventId
         * @param {string} email
         * @param {string} [date]
         * @param {string} [ownerId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleParticipantForEventDashboard: function (eventId, email, date, ownerId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getSingleParticipantForEventDashboard(eventId, email, date, ownerId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get single ticket event dashboard
         * @param {string} eventId
         * @param {string} ticketId
         * @param {string} [date]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleTicketEventDashboard: function (eventId, ticketId, date, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getSingleTicketEventDashboard(eventId, ticketId, date, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get ticket events dashboard
         * @param {string} eventId
         * @param {string} [date]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketEventsDashboard: function (eventId, date, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getTicketEventsDashboard(eventId, date, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get ticket reports for event dashboard
         * @param {string} eventId
         * @param {string} [date]
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {'all' | 'mine'} [filterTicket]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketReportsForEventDashboard: function (eventId, date, filter, filterTicket, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getTicketReportsForEventDashboard(eventId, date, filter, filterTicket, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.TicketEventDashboardApiFp = TicketEventDashboardApiFp;
/**
 * TicketEventDashboardApi - factory interface
 * @export
 */
var TicketEventDashboardApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.TicketEventDashboardApiFp)(configuration);
    return {
        /**
         *
         * @summary Download ticket reports for event dashboard
         * @param {string} eventId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {InputDownloadTicketReportsForEventDashboard} [inputDownloadTicketReportsForEventDashboard]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTicketReportsForEventDashboard: function (eventId, filter, inputDownloadTicketReportsForEventDashboard, options) {
            return localVarFp.downloadTicketReportsForEventDashboard(eventId, filter, inputDownloadTicketReportsForEventDashboard, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get participants for event dashboard
         * @param {string} eventId
         * @param {string} [date]
         * @param {string} [download]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParticipantsForEventDashboard: function (eventId, date, download, options) {
            return localVarFp.getParticipantsForEventDashboard(eventId, date, download, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get single participant for event dashboard
         * @param {string} eventId
         * @param {string} email
         * @param {string} [date]
         * @param {string} [ownerId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleParticipantForEventDashboard: function (eventId, email, date, ownerId, options) {
            return localVarFp.getSingleParticipantForEventDashboard(eventId, email, date, ownerId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get single ticket event dashboard
         * @param {string} eventId
         * @param {string} ticketId
         * @param {string} [date]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleTicketEventDashboard: function (eventId, ticketId, date, options) {
            return localVarFp.getSingleTicketEventDashboard(eventId, ticketId, date, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get ticket events dashboard
         * @param {string} eventId
         * @param {string} [date]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketEventsDashboard: function (eventId, date, options) {
            return localVarFp.getTicketEventsDashboard(eventId, date, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get ticket reports for event dashboard
         * @param {string} eventId
         * @param {string} [date]
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {'all' | 'mine'} [filterTicket]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketReportsForEventDashboard: function (eventId, date, filter, filterTicket, options) {
            return localVarFp.getTicketReportsForEventDashboard(eventId, date, filter, filterTicket, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.TicketEventDashboardApiFactory = TicketEventDashboardApiFactory;
/**
 * TicketEventDashboardApi - object-oriented interface
 * @export
 * @class TicketEventDashboardApi
 * @extends {BaseAPI}
 */
var TicketEventDashboardApi = /** @class */ (function (_super) {
    __extends(TicketEventDashboardApi, _super);
    function TicketEventDashboardApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Download ticket reports for event dashboard
     * @param {string} eventId
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {InputDownloadTicketReportsForEventDashboard} [inputDownloadTicketReportsForEventDashboard]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketEventDashboardApi
     */
    TicketEventDashboardApi.prototype.downloadTicketReportsForEventDashboard = function (eventId, filter, inputDownloadTicketReportsForEventDashboard, options) {
        var _this = this;
        return (0, exports.TicketEventDashboardApiFp)(this.configuration).downloadTicketReportsForEventDashboard(eventId, filter, inputDownloadTicketReportsForEventDashboard, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get participants for event dashboard
     * @param {string} eventId
     * @param {string} [date]
     * @param {string} [download]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketEventDashboardApi
     */
    TicketEventDashboardApi.prototype.getParticipantsForEventDashboard = function (eventId, date, download, options) {
        var _this = this;
        return (0, exports.TicketEventDashboardApiFp)(this.configuration).getParticipantsForEventDashboard(eventId, date, download, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get single participant for event dashboard
     * @param {string} eventId
     * @param {string} email
     * @param {string} [date]
     * @param {string} [ownerId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketEventDashboardApi
     */
    TicketEventDashboardApi.prototype.getSingleParticipantForEventDashboard = function (eventId, email, date, ownerId, options) {
        var _this = this;
        return (0, exports.TicketEventDashboardApiFp)(this.configuration).getSingleParticipantForEventDashboard(eventId, email, date, ownerId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get single ticket event dashboard
     * @param {string} eventId
     * @param {string} ticketId
     * @param {string} [date]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketEventDashboardApi
     */
    TicketEventDashboardApi.prototype.getSingleTicketEventDashboard = function (eventId, ticketId, date, options) {
        var _this = this;
        return (0, exports.TicketEventDashboardApiFp)(this.configuration).getSingleTicketEventDashboard(eventId, ticketId, date, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get ticket events dashboard
     * @param {string} eventId
     * @param {string} [date]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketEventDashboardApi
     */
    TicketEventDashboardApi.prototype.getTicketEventsDashboard = function (eventId, date, options) {
        var _this = this;
        return (0, exports.TicketEventDashboardApiFp)(this.configuration).getTicketEventsDashboard(eventId, date, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get ticket reports for event dashboard
     * @param {string} eventId
     * @param {string} [date]
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {'all' | 'mine'} [filterTicket]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketEventDashboardApi
     */
    TicketEventDashboardApi.prototype.getTicketReportsForEventDashboard = function (eventId, date, filter, filterTicket, options) {
        var _this = this;
        return (0, exports.TicketEventDashboardApiFp)(this.configuration).getTicketReportsForEventDashboard(eventId, date, filter, filterTicket, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return TicketEventDashboardApi;
}(base_1.BaseAPI));
exports.TicketEventDashboardApi = TicketEventDashboardApi;
/**
 * TicketGroupApi - axios parameter creator
 * @export
 */
var TicketGroupApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Create ticket group
         * @param {InputCreateTicketGroup} [inputCreateTicketGroup]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTicketGroup: function (inputCreateTicketGroup, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/ticket-group/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputCreateTicketGroup, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary deletes a ticket groyp
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTicketGroupById: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('deleteTicketGroupById', 'id', id);
                            localVarPath = "/ticket-group/{id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary edit a ticket group
         * @param {InputEditTicketGroup} [inputEditTicketGroup]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editTicketGroup: function (inputEditTicketGroup, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/ticket-group/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputEditTicketGroup, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary get single ticket group by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketGroypById: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('getTicketGroypById', 'id', id);
                            localVarPath = "/ticket-group/{id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary List my ticket groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMyTicketGroups: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/ticket-group/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.TicketGroupApiAxiosParamCreator = TicketGroupApiAxiosParamCreator;
/**
 * TicketGroupApi - functional programming interface
 * @export
 */
var TicketGroupApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.TicketGroupApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Create ticket group
         * @param {InputCreateTicketGroup} [inputCreateTicketGroup]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTicketGroup: function (inputCreateTicketGroup, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createTicketGroup(inputCreateTicketGroup, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary deletes a ticket groyp
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTicketGroupById: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteTicketGroupById(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary edit a ticket group
         * @param {InputEditTicketGroup} [inputEditTicketGroup]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editTicketGroup: function (inputEditTicketGroup, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.editTicketGroup(inputEditTicketGroup, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary get single ticket group by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketGroypById: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getTicketGroypById(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary List my ticket groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMyTicketGroups: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listMyTicketGroups(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.TicketGroupApiFp = TicketGroupApiFp;
/**
 * TicketGroupApi - factory interface
 * @export
 */
var TicketGroupApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.TicketGroupApiFp)(configuration);
    return {
        /**
         *
         * @summary Create ticket group
         * @param {InputCreateTicketGroup} [inputCreateTicketGroup]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTicketGroup: function (inputCreateTicketGroup, options) {
            return localVarFp.createTicketGroup(inputCreateTicketGroup, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary deletes a ticket groyp
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTicketGroupById: function (id, options) {
            return localVarFp.deleteTicketGroupById(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary edit a ticket group
         * @param {InputEditTicketGroup} [inputEditTicketGroup]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editTicketGroup: function (inputEditTicketGroup, options) {
            return localVarFp.editTicketGroup(inputEditTicketGroup, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary get single ticket group by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketGroypById: function (id, options) {
            return localVarFp.getTicketGroypById(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary List my ticket groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMyTicketGroups: function (options) {
            return localVarFp.listMyTicketGroups(options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.TicketGroupApiFactory = TicketGroupApiFactory;
/**
 * TicketGroupApi - object-oriented interface
 * @export
 * @class TicketGroupApi
 * @extends {BaseAPI}
 */
var TicketGroupApi = /** @class */ (function (_super) {
    __extends(TicketGroupApi, _super);
    function TicketGroupApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Create ticket group
     * @param {InputCreateTicketGroup} [inputCreateTicketGroup]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketGroupApi
     */
    TicketGroupApi.prototype.createTicketGroup = function (inputCreateTicketGroup, options) {
        var _this = this;
        return (0, exports.TicketGroupApiFp)(this.configuration).createTicketGroup(inputCreateTicketGroup, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary deletes a ticket groyp
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketGroupApi
     */
    TicketGroupApi.prototype.deleteTicketGroupById = function (id, options) {
        var _this = this;
        return (0, exports.TicketGroupApiFp)(this.configuration).deleteTicketGroupById(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary edit a ticket group
     * @param {InputEditTicketGroup} [inputEditTicketGroup]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketGroupApi
     */
    TicketGroupApi.prototype.editTicketGroup = function (inputEditTicketGroup, options) {
        var _this = this;
        return (0, exports.TicketGroupApiFp)(this.configuration).editTicketGroup(inputEditTicketGroup, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary get single ticket group by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketGroupApi
     */
    TicketGroupApi.prototype.getTicketGroypById = function (id, options) {
        var _this = this;
        return (0, exports.TicketGroupApiFp)(this.configuration).getTicketGroypById(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary List my ticket groups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketGroupApi
     */
    TicketGroupApi.prototype.listMyTicketGroups = function (options) {
        var _this = this;
        return (0, exports.TicketGroupApiFp)(this.configuration).listMyTicketGroups(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return TicketGroupApi;
}(base_1.BaseAPI));
exports.TicketGroupApi = TicketGroupApi;
/**
 * TicketSubGroupApi - axios parameter creator
 * @export
 */
var TicketSubGroupApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Create ticket sub group
         * @param {InputCreateEditTicketSubGroup} [inputCreateEditTicketSubGroup]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTicketSubGroup: function (inputCreateEditTicketSubGroup, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/ticket-sub-group/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputCreateEditTicketSubGroup, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Delete ticket sub group by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTicketSubGroupById: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('deleteTicketSubGroupById', 'id', id);
                            localVarPath = "/ticket-sub-group/{id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Edit ticket sub group
         * @param {string} id
         * @param {InputCreateEditTicketSubGroup} [inputCreateEditTicketSubGroup]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editTicketSubGroup: function (id, inputCreateEditTicketSubGroup, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('editTicketSubGroup', 'id', id);
                            localVarPath = "/ticket-sub-group/{id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputCreateEditTicketSubGroup, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get ticket sub groyp by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketSubGroypById: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('getTicketSubGroypById', 'id', id);
                            localVarPath = "/ticket-sub-group/{id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.TicketSubGroupApiAxiosParamCreator = TicketSubGroupApiAxiosParamCreator;
/**
 * TicketSubGroupApi - functional programming interface
 * @export
 */
var TicketSubGroupApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.TicketSubGroupApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Create ticket sub group
         * @param {InputCreateEditTicketSubGroup} [inputCreateEditTicketSubGroup]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTicketSubGroup: function (inputCreateEditTicketSubGroup, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createTicketSubGroup(inputCreateEditTicketSubGroup, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Delete ticket sub group by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTicketSubGroupById: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteTicketSubGroupById(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Edit ticket sub group
         * @param {string} id
         * @param {InputCreateEditTicketSubGroup} [inputCreateEditTicketSubGroup]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editTicketSubGroup: function (id, inputCreateEditTicketSubGroup, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.editTicketSubGroup(id, inputCreateEditTicketSubGroup, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get ticket sub groyp by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketSubGroypById: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getTicketSubGroypById(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.TicketSubGroupApiFp = TicketSubGroupApiFp;
/**
 * TicketSubGroupApi - factory interface
 * @export
 */
var TicketSubGroupApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.TicketSubGroupApiFp)(configuration);
    return {
        /**
         *
         * @summary Create ticket sub group
         * @param {InputCreateEditTicketSubGroup} [inputCreateEditTicketSubGroup]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTicketSubGroup: function (inputCreateEditTicketSubGroup, options) {
            return localVarFp.createTicketSubGroup(inputCreateEditTicketSubGroup, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Delete ticket sub group by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTicketSubGroupById: function (id, options) {
            return localVarFp.deleteTicketSubGroupById(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Edit ticket sub group
         * @param {string} id
         * @param {InputCreateEditTicketSubGroup} [inputCreateEditTicketSubGroup]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editTicketSubGroup: function (id, inputCreateEditTicketSubGroup, options) {
            return localVarFp.editTicketSubGroup(id, inputCreateEditTicketSubGroup, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get ticket sub groyp by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketSubGroypById: function (id, options) {
            return localVarFp.getTicketSubGroypById(id, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.TicketSubGroupApiFactory = TicketSubGroupApiFactory;
/**
 * TicketSubGroupApi - object-oriented interface
 * @export
 * @class TicketSubGroupApi
 * @extends {BaseAPI}
 */
var TicketSubGroupApi = /** @class */ (function (_super) {
    __extends(TicketSubGroupApi, _super);
    function TicketSubGroupApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Create ticket sub group
     * @param {InputCreateEditTicketSubGroup} [inputCreateEditTicketSubGroup]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketSubGroupApi
     */
    TicketSubGroupApi.prototype.createTicketSubGroup = function (inputCreateEditTicketSubGroup, options) {
        var _this = this;
        return (0, exports.TicketSubGroupApiFp)(this.configuration).createTicketSubGroup(inputCreateEditTicketSubGroup, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Delete ticket sub group by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketSubGroupApi
     */
    TicketSubGroupApi.prototype.deleteTicketSubGroupById = function (id, options) {
        var _this = this;
        return (0, exports.TicketSubGroupApiFp)(this.configuration).deleteTicketSubGroupById(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Edit ticket sub group
     * @param {string} id
     * @param {InputCreateEditTicketSubGroup} [inputCreateEditTicketSubGroup]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketSubGroupApi
     */
    TicketSubGroupApi.prototype.editTicketSubGroup = function (id, inputCreateEditTicketSubGroup, options) {
        var _this = this;
        return (0, exports.TicketSubGroupApiFp)(this.configuration).editTicketSubGroup(id, inputCreateEditTicketSubGroup, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get ticket sub groyp by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketSubGroupApi
     */
    TicketSubGroupApi.prototype.getTicketSubGroypById = function (id, options) {
        var _this = this;
        return (0, exports.TicketSubGroupApiFp)(this.configuration).getTicketSubGroypById(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return TicketSubGroupApi;
}(base_1.BaseAPI));
exports.TicketSubGroupApi = TicketSubGroupApi;
/**
 * UserApi - axios parameter creator
 * @export
 */
var UserApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Get user by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('getUserById', 'id', id);
                            localVarPath = "/user/{id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication headerAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-Auth-Token", configuration)];
                        case 1:
                            // authentication headerAuth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.UserApiAxiosParamCreator = UserApiAxiosParamCreator;
/**
 * UserApi - functional programming interface
 * @export
 */
var UserApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.UserApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Get user by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getUserById(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.UserApiFp = UserApiFp;
/**
 * UserApi - factory interface
 * @export
 */
var UserApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.UserApiFp)(configuration);
    return {
        /**
         *
         * @summary Get user by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById: function (id, options) {
            return localVarFp.getUserById(id, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.UserApiFactory = UserApiFactory;
/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
var UserApi = /** @class */ (function (_super) {
    __extends(UserApi, _super);
    function UserApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Get user by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.getUserById = function (id, options) {
        var _this = this;
        return (0, exports.UserApiFp)(this.configuration).getUserById(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return UserApi;
}(base_1.BaseAPI));
exports.UserApi = UserApi;
